/* eslint-disable react-hooks/exhaustive-deps */
import Logo from "../../../../components/Logo";
import Header from "../../components/Header";
import { ReactComponent as Delivery } from "../../../../assets/svg/package-box.svg";
import { ReactComponent as Pickup } from "../../../../assets/svg/package.svg";
import ItemAction from "../../components/ItemAction";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { set_loader } from "../../../../redux/actions/loader";
import { useSelector } from "react-redux";

const Home = () => {
  const navigate = useNavigate();
  const dispatch: any = useDispatch();
  const { session } = useSelector((state: any) => state);
  const assets = session?.profile?.assets?.assets;

  useEffect(() => {
    const timeout: any = setTimeout(() => {
      dispatch(set_loader({ is_loading: false }));
      console.log("CLOSE LOADER");
    }, 5000);

    return () => {
      clearInterval(timeout);
      console.log("CLEAR INTERVAL");
    };
  }, []);

  return (
    <div className="container-fluid h-100">
      <Header />
      <div className="content-section">
        <div className="row align-items-center justify-content-center my-5">
          <div className="col-6">
            <Logo />
          </div>
        </div>
        <div className="row align-items-center justify-content-center mt-5">
        <ItemAction
            label={assets?.pickup_without_reservation_home_btn_load_title || "Carga"}
            Image={Delivery}
            description={assets?.pickup_without_reservation_home_btn_load_description || "Haga click aquí para hacer una carga de un pedido."}
            handleOnClick={() => navigate("/delivery", { replace: true })}
          />
          <ItemAction
            label={assets?.pickup_without_reservation_home_btn_pickup_title || "Retiro"}
            Image={Pickup}
            description={assets?.pickup_without_reservation_home_btn_pickup_description || "Haga click aquí para hacer un retiro de un pedido."}
            handleOnClick={() => navigate("/pickup", { replace: true })}
          />
        </div>
      </div>
    </div>
  );
};

export default Home;
