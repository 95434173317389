/* eslint-disable react-hooks/exhaustive-deps */
import Header from "../../components/Header";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { set_loader } from "../../../../redux/actions/loader";
import Drawer from "../../../../components/Drawer";
import NumberKeyboard from "../../components/NumberKeyboard";
import { useNavigate } from "react-router-dom";
import { _pushToastMessage } from "../../../../helpers/messages";
import NumberList from "../../../../components/NumberList";
import HiddenQrRreaderMyBox from "../../components/HiddenQrReaderMyBox";

const MyBox = () => {
  const [deliveryDrawer, setDeliveryDrawer] = useState({
    open: false,
    data: {},
  });

  const navigate = useNavigate();

  const dispatch: any = useDispatch();
  useEffect(() => {
    const timeout: any = setTimeout(() => {
      dispatch(set_loader({ is_loading: false }));
      console.log("CLOSE LOADER");
    }, 5000);

    return () => {
      clearInterval(timeout);
      console.log("CLEAR INTERVAL");
    };
  }, []);

  const _handleOnConfirmKeyboard = (apartament: number) => {
    if (!apartament) {
      _pushToastMessage({
        type: "warning",
        text: "Ingrese un número de departamento / casa.",
        header: "Aviso",
      });
      return;
    }
    navigate(`/delivery-with-apartament/${apartament}`, { replace: true });
  };

  return (
    <div className="container-fluid h-100">
      <Header />
      <HiddenQrRreaderMyBox />
      <Drawer
        isOpen={deliveryDrawer.open}
        onClose={() => setDeliveryDrawer({ open: false, data: {} })}
      >
        <NumberKeyboard
          isOpen={deliveryDrawer.open}
          onConfirm={_handleOnConfirmKeyboard}
        />
      </Drawer>

      <div className="content-section">
        <div className="row justify-content-center mt-5">
          <div className="w-75 text-center">
            <h1 className="">
              <i></i>
            </h1>
          </div>
        </div>
        <div className="d-flex  justify-content-center mt-5 px-4">
          <div className="col-6 d-flex flex-column">
            <div className="mb-4" style={{ fontSize: "24px" }}>
              ¿Como <b>guardo</b> o <b>saco</b> cosas de mi casillero?
            </div>
            <div className="px-2 size-09">
              <NumberList
                color="#FF0"
                number={1}
                title="Escanea el QR"
                text="de tu cedula de identidad"
              />
              <NumberList
                color="#FF0"
                number={2}
                title="Ingresa tu codigo PIN"
                text="Te lo enviamos por correo"
              />
              <NumberList
                color="#FF0"
                number={4}
                title="Guarda o saca tus pertenencias"
                text="y cierra la puerta"
              />

              <NumberList
                color="#FF0"
                number={5}
                title="Puedes guardar y sacar"
                text="cosas las veces que quieras presionando en Mi Casillero ;)"
              />
            </div>
          </div>
        </div>

        <div className="w-100 text-center mt-5">
          <button
            className="px-5 py-2 main-button-yellow bold"
            onClick={() => navigate("/")}
          >
            Volver
          </button>
        </div>
        <div className="w-100 text-center mt-5">
          <button
            className="px-4 py-2 bold btn color-white border"
            onClick={() => navigate("/help-user/phone")}
          >
            ¿No puedes retirar tus items? Presiona aquí y te llamaremos
          </button>
        </div>
      </div>
    </div>
  );
};

export default MyBox;
