/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Keyboard from "../../../../components/Keyboard";
import Logo from "../../../../components/Logo";
import { io } from "socket.io-client";
import { useSelector } from "react-redux";
import { _handleError } from "../../../../helpers/errors";
import { useDispatch } from "react-redux";
import { set_loader } from "../../../../redux/actions/loader";
import { set_pickup } from "../../../../redux/actions/pickup";
import LocalReservationService from "../../../../services/local_reservation.service";
import LineInput from "../../../../components/LineInput";

const Pickup = () => {
  const [state, setState] = useState<any>({
    fields: {
      field1: {
        value: "",
        selected: true,
      },
      field2: {
        value: "",
        selected: false,
      },
      field3: {
        value: "",
        selected: false,
      },
      field4: {
        value: "",
        selected: false,
      },
      field5: {
        value: "",
        selected: false,
      },
      field6: {
        value: "",
        selected: false,
      },
    },
    qrcode: "",
  });

  const keyboardRef = useRef<any>();
  const inputRef: any = useRef<any>();
  const navigate = useNavigate();
  const { session } = useSelector((state: any) => state);
  const Reservation = new LocalReservationService();
  const dispatch: any = useDispatch();

  useEffect(() => {
    const qrcodelength: any = state.qrcode.length;

    Array.from({ length: 6 }).forEach((_: any, index: any) => {
      state.fields[`field${index + 1}`] = {
        value: state.qrcode[index],
        selected: index === qrcodelength ? true : false,
      };
    });

    inputRef.current.value = state.qrcode;

    setState({
      ...state,
    });

    if (qrcodelength === 6) {
      _handleValidateCode(state.qrcode);
    }
  }, [state.qrcode]);

  useEffect(() => {
    const interval: any = setInterval(() => {
      inputRef.current.focus();
    }, 100);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    const socket: any = io("http://127.0.0.1:3333");

    socket.on("news", (data: any) => {
      console.log(data);
    });

    socket.on("code", (data: any) => {
      const code: any = data.replace(/[\r\n]/gm, "");
      setState({ ...state, qrcode: code });
      keyboardRef.current.setInput(code);
    });

    socket.on("connect_error", () => console.log("CONECT ERROR"));
    socket.on("reconnect_error", () => console.log("RECONECT ERROR"));

    dispatch(set_loader({ is_loading: false }));

    return () => {
      console.log("Destroy socket connection");
      socket.destroy();
    };
  }, []);

  const _handleOnKeyPress = (button: any) => {
    if (button !== "{bksp}") {
      if (state.qrcode.length <= 5) {
        setState({ ...state, qrcode: state.qrcode + button });
      } else {
        setState({ ...state, qrcode: button });
        keyboardRef.current.setInput(button);
      }
    } else {
      state.qrcode = state.qrcode.substring(0, state.qrcode.length - 1);
      setState({ ...state });
      keyboardRef.current.setInput(state.qrcode);
    }
  };

  const _handleOnChange = (value: any) => {
    console.log("ONCHANGE", value);
  };

  const _handleValidateCode = async (code: any) => {
    try {
      dispatch(set_loader({ is_loading: true }));
      const response: any = await Reservation.checkCode({
        terminal_id: session?.profile?.id,
        code: code,
      });

      dispatch(set_loader({ is_loading: false }));
      dispatch(
        set_pickup({ reservation: response.data, boxes: response.data?.boxes })
      );
      navigate("/open-box-pickup");
    } catch (e: any) {
      _handleError(e, e.message);
      dispatch(set_loader({ is_loading: false }));
    }
  };

  const _handleChangeInput = (e: any) => {
    if (e.target.value.length > 6) {
      const firstLetter: any = e.target.value.substring(
        e.target.value.length - 1
      );
      setState({ ...state, qrcode: firstLetter?.toUpperCase() });
    } else {
      setState({ ...state, qrcode: e?.target?.value?.toUpperCase() });
    }
  };

  const _clearField = () => {
    inputRef.current.value = "";
    setState({ ...state, qrcode: "" });
  };

  return (
    <div className="container-fluid h-100">
      <input
        type="text"
        ref={inputRef}
        id="codetext"
        style={{ opacity: 0, position: "absolute" }}
        onChange={_handleChangeInput}
        maxLength={7}
      />
      <div className="w-100 text-center my-4">
        <Logo style={{ width: "100px" }} />
      </div>
      <div className="d-flex justify-content-center align-items-center p-0">
        <div
          className=" d-flex align-items-center justify-content-around flex-column p-5"
          style={{
            background: "rgba(255, 255, 255, 0.1)",
            width: "90%",
            borderRadius: "30px",
            height: "650px",
          }}
        >
          <div className="w-100 text-center bold size-14">
            Introduce o escanea el código QR
          </div>

          <div>
            <div
              className="w-100 d-flex justify-content-center my-4 pb-2"
              style={{
                color: "#000",
                background: "rgba(255, 255, 255, 0.1)",
                borderRadius: "10px",
              }}
            >
              {Object.keys(state.fields).map((key: any) => (
                <LineInput {...state.fields[key]} key={key} />
              ))}
            </div>
          </div>

          <div
            className="w-100 keyboard"
            style={{ color: "#000", height: "65vh" }}
          >
            <Keyboard
              onKeyPress={_handleOnKeyPress}
              onChange={_handleOnChange}
              keyboardRef={keyboardRef}
              layoutType={"spanish_basic_without_space"}
            />
          </div>

          <div className="text-center my-3">
            <button
              className="main-button-yellow px-5 py-2 bold"
              onClick={_clearField}
            >
              Limpiar
            </button>
            <button
              className="main-button px-5 py-2 ms-3 bold"
              onClick={() => navigate("/")}
            >
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pickup;
