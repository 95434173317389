import { useEffect } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import SignIn from "../components/SignIn";
import Boxes from "../modules/Events/pages/Boxes";
import Companies from "../modules/Events/pages/Companies";
import Delivery from "../modules/Events/pages/Delivery";
import Home from "../modules/Events/pages/Home";
// import OpenBoxDelivery from "../modules/Events/pages/OpenBoxDelivery";
import OpenBoxDeliveryServices from "../modules/Events/pages/OpenBoxDeliveryServices";
import OpenBoxPickup from "../modules/Events/pages/OpenBoxPickup";
import Pickup from "../modules/Events/pages/Pickup";
import Services from "../modules/Events/pages/Services";
import Success from "../modules/Events/pages/Success";
import DashboardRouter from "./DashboardRouter";
import Apartaments from "../modules/Events/pages/Apartaments";
import OpenBox from "../modules/Events/pages/OpenBox";
import Confirmation from "../modules/Events/pages/Confirmation";
import ApartmentKeyboard from "../modules/Events/pages/ApartmentKeyboard";
import OpenBoxCustody from "../modules/Events/pages/OpenBoxCustody";
import ConfirmationCustody from "../modules/Events/pages/ConfirmationCustody";
import HelpUserKeyboard from "../modules/Events/pages/HelpUserKeyboard";
import HelpUserSuccess from "../modules/Events/pages/HelpUserSuccess";
import HelpUserFailed from "../modules/Events/pages/HelpUserFailed";
import MyBox from "../modules/Events/pages/MyBox";
import MyBoxPickup from "../modules/Events/pages/MyBoxPickup";
import ValidatePinCode from "../modules/Events/pages/ValidatePinCode";

export default function EventsRouter() {
  const { session } = useSelector((state: any) => state);
  useEffect(() => {
    import("../modules/Events/styles/_global_events.scss");
  }, []);
  return (
    <BrowserRouter>
      <SignIn open={!session.is_connected || session.sign_in_component} />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/validate-pin" element={<ValidatePinCode />} />

        {/** sac routes */}
        <Route path="/help-user/phone" element={<HelpUserKeyboard />} />
        <Route path="/help-user/phone/success" element={<HelpUserSuccess />} />
        <Route path="/help-user/phone/failed" element={<HelpUserFailed />} />

        {/** open boxes without actions */}
        <Route path="/my-box" element={<MyBox />} />
        <Route path="/my-box-pickup" element={<MyBoxPickup />} />

        {/** delivery boxes */}
        <Route path="/open-box-delivery" element={<OpenBox />} />
        <Route path="/confirmation" element={<Confirmation />} />

        {/** pickup boxes */}
        <Route path="/open-box-pickup" element={<OpenBoxPickup />} />

        {/** old routes */}
        <Route path="/delivery" element={<Delivery />} />
        <Route path="/available-boxes" element={<Boxes />} />
        <Route path="/companies" element={<Companies />} />
        <Route path="/open-box-delivery/:apartment" element={<OpenBox />} />
        <Route path="/open-box-custody/:phone" element={<OpenBoxCustody />} />
        <Route
          path="/services/open-box-delivery-services"
          element={<OpenBoxDeliveryServices />}
        />
        <Route path="/success" element={<Success />} />
        <Route path="/pickup" element={<Pickup />} />
        <Route path="/info-apartment" element={<ApartmentKeyboard />} />
        <Route path="/services" element={<Services />} />
        <Route
          path="/delivery-with-apartament/:apartment"
          element={<Apartaments />}
        />
        <Route
          path="/confirmation/custody/:phone"
          element={<ConfirmationCustody />}
        />
        <Route path="/dashboard/*" element={<DashboardRouter />} />

        {/* <Route path="/open-box-pickup" element={<OpenBoxPickup />} /> */}
        {/* <Route path="/open-box-delivery" element={<OpenBoxDelivery />} /> */}
        {/* <Route path="/confirmation/:apartment" element={<Confirmation />} /> */}
        {/* <Route path="/info-custody" element={<CustodyKeyboard />} /> */}
      </Routes>
    </BrowserRouter>
  );
}
