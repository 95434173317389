/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef } from "react";
import { io } from "socket.io-client";
import { _pushToastMessage } from "../../../../helpers/messages";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { set_loader } from "../../../../redux/actions/loader";
import { useNavigate } from "react-router-dom";
import LendingService from "../../../../services/lending.service";
import { set_boxes } from "../../../../redux/actions/boxes";
import { debounce } from "lodash";
import { clear_codes } from "../../../../redux/actions/codes";

interface IHiddenQrRreaderProps {
  onChange?: any;
}

const HiddenQrRreader = ({ onChange }: IHiddenQrRreaderProps) => {
  const { session } = useSelector((state: any) => state);

  const Lending = new LendingService();
  const dispatch: any = useDispatch();
  const navigate = useNavigate();
  const inputRef: any = useRef<any>();
  const debouncedSave = useRef(
    debounce((nextValue) => _formatAndCheckData(nextValue), 700)
  ).current;

  /**
   * Focus input
   * clear interval
   * @returns void
   */
  useEffect(() => {
    const interval: any = setInterval(() => {
      inputRef.current.focus();
    }, 100);

    if (session.sign_in_component) {
      clearInterval(interval);
    }

    return () => {
      clearInterval(interval);
    };
  }, [session]);

  /**
   * Socket connection
   */
  useEffect(() => {
    const socket: any = io("http://127.0.0.1:3333");
    socket.on("news", (data: any) => {
      console.log(data);
    });

    socket.on("code", (data: any) => {
      const code: any = data.replace(/[\r\n]/gm, "");

      const url: any = code.toLowerCase();

      const search = url.split("?")[1];

      const urlSearchParams = new URLSearchParams(search);

      if (urlSearchParams.has("run")) {
        const run = urlSearchParams.get("run");

        if (!run) return;

        if (run.length < 6) return;

        const finalCode = run?.replace(/[^a-zA-Z0-9]/g, "");

        if (onChange) onChange(finalCode);

        _validateCode(finalCode);
        return;
      }

      _pushToastMessage({
        header: "Error",
        text: "Este código no es válido",
        type: "error",
      });
    });

    socket.on("connect_error", () => console.log("CONECT ERROR"));
    socket.on("reconnect_error", () => console.log("RECONECT ERROR"));

    return () => {
      console.log("Destroy socket connection");
      socket.destroy();
    };
  }, []);

  const _validateCode = async (code: any) => {
    try {
      dispatch(set_loader({ is_loading: true }));

      const response = await Lending.getLendingByDocument({
        terminal_id: session.profile.id,
        code: code.toLowerCase(),
      });

      const pickupPayload = {
        reservation_id: response.data.id,
        code: response.data.code,
        terminal_id: response.data.terminal_id,
      };

      await Lending.pickup(pickupPayload);

      const boxes = response.data.boxes;

      dispatch(set_boxes({ box_list: boxes }));

      navigate("/pickup-article-success");

      dispatch(set_loader({ is_loading: false }));
    } catch (e: any) {
      dispatch(set_loader({ is_loading: false }));
      _pushToastMessage({
        text:
          e?.response?.data?.message ||
          e?.message ||
          "No fue posible encontrar una reserva con este codigo.",
        type: "error",
        header: "Error",
      });
    }
  };

  /* Proccess data from input */
  const _formatAndCheckData = (qrcode: any) => {
    const loaderComponent = document.querySelector<HTMLElement>(".loader_elock_dynamic");
    if(loaderComponent?.style?.display === 'flex') {
      _pushToastMessage({
        header: "Aviso",
        text: "Espere un momento por favor y vuelva a intentarlo.",
        type: "warning",
      });
      return;
    }

    dispatch(set_loader({ is_loading: true }));
    inputRef.current.value = qrcode?.toLowerCase();

    const urlToSearchParamsMethod: any = qrcode?.toLowerCase();
    const urlToDoubleSplitMethod: any = qrcode?.toLowerCase();
    const dataReadedFromArticle: any = qrcode?.toLowerCase();

    /* check if exist some text */
    if (!urlToSearchParamsMethod) {
      dispatch(set_loader({ is_loading: false }));
      _pushToastMessage({
        header: "Error",
        text: "El código informado no es valido. ref:.ERR001",
        type: "error",
      });
      return;
    }

    /* split string */
    const search = urlToSearchParamsMethod.split("?")[1];

    const urlSearchParams = new URLSearchParams(search);

    /* search params method */
    if (urlSearchParams.has("run")) {
      const run = urlSearchParams.get("run");
      if (!run) {
        _pushToastMessage({
          header: "Error",
          text: "Este código no es válido. ref:.ERR002",
          type: "error",
        });
        dispatch(set_loader({ is_loading: false }));
        return;
      }
      const finalCode = run?.replace(/[^a-zA-Z0-9]/g, "");
      console.log("FINAL CODE", finalCode);
      if (onChange) onChange(finalCode);
      _validateCode(finalCode);
      inputRef.current.value = "";
      return;
    }

    /* double split method */
    if (urlToDoubleSplitMethod.indexOf("docstatus_run¿") !== -1) {
      try {
        const first_split = urlToDoubleSplitMethod.split("docstatus_run¿");
        const last_split = first_split[1].split("/type");
        const rut = last_split[0]?.replace(/[^a-zA-Z0-9]/g, "");
        if (onChange) onChange(rut);
        _validateCode(rut);
        inputRef.current.value = "";
        return;
      } catch (e) {
        dispatch(set_loader({ is_loading: false }));
        _pushToastMessage({
          header: "Error",
          text: "Este código no es válido. ref:.ERR003",
          type: "error",
        });
      }
    }

    /* data readed from article */
    dispatch(clear_codes());
    navigate(`/delivery-articles?add=${dataReadedFromArticle}`, {
      replace: true,
    });
  };

  const _handleChangeInput = (e: any) => {
    debouncedSave(e?.target?.value);
  };

  return (
    <div style={{ position: "absolute", top: "-100px" }}>
      <input
        type="text"
        ref={inputRef}
        id="codetext"
        style={{ opacity: 1, color: "#000" }}
        onChange={_handleChangeInput}
      />
    </div>
  );
};

export default HiddenQrRreader;
