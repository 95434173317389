import React from "react";
import { Route, Routes } from "react-router-dom";
import DashboardCompanies from "../pages/dashboard/DashboardCompanies";
import DashboardDoors from "../pages/dashboard/DashboardDoors";
import DashboardHomeOffline from "../pages/dashboard/DashboardHomeOffline";
import DashboardModules from "../pages/dashboard/DashboardModules";
import DashboardOrders from "../pages/dashboard/DashboardOrders";
import DashboardLending from "../pages/dashboard/DashboardLending";
import DashboardOffline from "../pages/dashboard/DashboardOffline";
import DashboardHome from "../pages/dashboard/DashboardHome";
import DashboardOrdersOffline from "../pages/dashboard/DashboardOrdersOffline";
import SignInQr from "../pages/SignInQr";
import DashboardVersion from "../pages/dashboard/DashboardVersion";
import "rsuite/dist/rsuite.min.css";

const DashboardRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<DashboardHome />} />
      <Route path="/boxes/offline" element={<DashboardHomeOffline />} />
      <Route path="/orders" element={<DashboardOrders />} />
      <Route path="/orders/offline" element={<DashboardOrdersOffline />} />
      <Route path="/companies" element={<DashboardCompanies />} />
      <Route path="/modules" element={<DashboardModules />} />
      <Route path="/doors" element={<DashboardDoors />} />
      <Route path="/lending" element={<DashboardLending />} />
      <Route path="/sync/offline" element={<DashboardOffline />} />
      <Route path="/version" element={<DashboardVersion />} />
      <Route path="/signin/qr" element={<SignInQr />} />
    </Routes>
  );
};

export default DashboardRouter;
