/* eslint-disable react-hooks/exhaustive-deps */
import Header from "../../components/Header";
import { useNavigate } from "react-router-dom";
import RegressiveCounter from "../../../../components/RegressiveCounter";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import DoorLockerService from "../../../../services/door_locker.service";
import { _handleError } from "../../../../helpers/errors";
const SuccessLoadArticles = () => {

  const navigate = useNavigate();
  const [intervalStateControl, setIntervalStateControl] = useState(0);
  const { boxes, session } = useSelector((state: any) => state);
  const DoorLocker = new DoorLockerService();
  const assets = session?.profile?.assets?.assets;
  const [message, setMessage] = useState(assets?.lending_load_step_two_text_one || "Guarda los articulos y cierra la puerta");

  const _handleConfirm = () => {
    setIntervalStateControl(intervalStateControl + 1);
    navigate("/load-articles");
  };

  useEffect(() => {
    _handleOpenDoor();
  }, []);

  const _handleOpenDoor = async () => {
    try {
      const { box_list } = boxes;
      const box = box_list[0];

      if(box.message_data) {
        if(box.message_data?.exceeded_first_mark?.is_exceeded) {
          setMessage(box.message_data?.exceeded_first_mark?.message);
        }

        if(box.message_data?.exceeded_second_mark?.is_exceeded) {
          setMessage(box.message_data?.exceeded_second_mark?.message);
        }

        delete box.message_data;
      }

      const payload: any = {
        com: session?.profile?.door_com_number,
        driveboard: box.desk_number,
        door: box.desk_door_number,
        box: box,
      };
      const response = await DoorLocker.openDoor(payload);

      if (response.data.status === "error") {
        throw new Error("No fue posible abrir esta puerta.");
      }
    } catch (e: any) {
      _handleError(e, e.message);
    }
  };

  return (
    <div className="container-fluid h-100">
      <Header />
      <div className="content">
        <div className="row mt-5">
          <div
            className="col-12 bold text-center d-flex align-items-center justify-content-center"
            style={{ textTransform: "uppercase", fontSize: "3rem", minHeight: "500px", }}
          >
            {message}
          </div>
        </div>

        <div className="row mt-5">
          <div className="col text-center">
            <button
              className="px-4 py-2 border-0 main-button bold"
              onClick={_handleConfirm}
            >
              <div className="d-flex justify-content-center align-items-center">
                <div className="me-3">Finalizar</div>
                <RegressiveCounter
                  clearIntervalAction={intervalStateControl}
                  handleCallback={_handleConfirm}
                />
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessLoadArticles;
