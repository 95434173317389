interface HomeActionButtonProps {
  Image: any;
  title: string;
  description: string;
  onClick?: () => void;
}
const HomeActionButton = ({
  Image,
  title,
  description,
  onClick,
}: HomeActionButtonProps) => {
  const mainStyles: any = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    maxWidth: "220px",
    height: "270px",
    borderRadius: "20px",
    backgroundColor: "rgba(255, 255, 255, 0.2)",
  };

  const imageStyles: any = {
    width: "68px",
    height: "68px",
  };

  return (
    <div className="p-3 mx-3" style={mainStyles} onClick={onClick}>
      <div className="d-flex justify-content-center align-items-center" style={{ height: "90px" }}>
        <Image style={imageStyles} />
      </div>
      <div className="d-flex justify-content-center align-items-center bold size-15 text-center" style={{ height: "90px" }}>
        {title}
      </div>
      <div className="d-flex justify-content-center align-items-center text-center" style={{ height: "90px" }}>
        {description}
      </div>
    </div>
  );
};

export default HomeActionButton;
