import { useEffect, useState } from "react";
import { ReactComponent as Circle } from "../../../../assets/svg/current_color_circle.svg";
import { ReactComponent as Box } from "../../../../assets/svg/current_color_box.svg";

const BoxComponent = ({ _handleOnclickBox, box }: any) => {
  const BigBox = () => {

    const [size, setSize] = useState('30px');

    useEffect(() => {

      setSize('70px');

      if(box.box_type_id === 1) {
        setSize('40px');
      }
      if(box.box_type_id === 2) {
        setSize('50px');
      }
      if(box.box_type_id === 3) {
        setSize('70px');
      }
    }, []);
    return (
      <div
        className="my-2 d-flex align-items-center justify-content-start px-4 text-black"
        style={{
          backgroundColor: "var(--events-default-backdrop-box-color)",
          width: "50%",
          height: "126px",
          borderRadius: "10px",
        }}
        onClick={() => _handleOnclickBox(box)}
      >
        <div
          className="d-flex justify-content-center align-items-center position-relative"
          style={{width: '100px'}}
        >
          <Circle className="events-dynamic-color-circle" style={{position: 'absolute', width: '100px', height: '100px'}}/>
          <Box className="events-dynamic-color-box" style={{position: 'absolute', width: size, height: size}}/>
        </div>
        <div className="ms-4 events-dynamic-box-text-color">
          <div className="bold">Abrir caja {box.name?.toLowerCase()}</div>
          <div className="d-flex flex-column size-08">
            <div className="me-3">Altura: {box.height / 10} cm</div>
            <div>Ancho: {box.width / 10} cm</div>
          </div>
        </div>
      </div>
    );
  };

  return <BigBox />;
};

export default BoxComponent;
