/* eslint-disable react-hooks/exhaustive-deps */
import Header from "../../components/Header";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { set_loader } from "../../../../redux/actions/loader";
import HiddenQrRreader from "../../components/HiddenQrReader";
import Drawer from "../../../../components/Drawer";
import NumberKeyboard from "../../components/NumberKeyboard";
import { useNavigate } from "react-router-dom";
import { _pushToastMessage } from "../../../../helpers/messages";
import NumberList from "../../../../components/NumberList";
import { useSelector } from "react-redux";
import { handleAllColorStyles } from "../../../../helpers/dynamic_styles";
import { checkIfSomePropertyHasAValue } from "../../../../helpers/functions";
import Multimedia from "../../components/Multimedia";

const Home = () => {
  const [deliveryDrawer, setDeliveryDrawer] = useState({
    open: false,
    data: {},
  });

  const [showMap, setShowMap] = useState(false);

  const [multimediaControl, setMultimediaControl] = useState({
    open: false,
    mediaData: {},
  });

  const navigate = useNavigate();
  const dispatch: any = useDispatch();
  const { session } = useSelector((state: any) => state);
  const { company } = session;
  const assets = session?.profile?.assets?.assets;

  useEffect(() => {
    const mediaData = {
      events_company_map_video_url: assets?.events_company_map_video_url,
      events_company_map_img_upload: assets?.events_company_map_img_upload,
      events_default_company_map_video_url:
        assets?.events_default_company_map_video_url,
      events_default_company_map_img_upload:
        assets?.events_default_company_map_img_upload,
    };
    setMultimediaControl({ open: false, mediaData });
    if (checkIfSomePropertyHasAValue(mediaData)) {
      setShowMap(true);
    }
  }, []);

  useEffect(() => {
    handleAllColorStyles(assets);
    const timeout: any = setTimeout(() => {
      dispatch(set_loader({ is_loading: false }));
      console.log("CLOSE LOADER");
    }, 5000);

    return () => {
      clearInterval(timeout);
      console.log("CLEAR INTERVAL");
    };
  }, []);

  const _handleOnConfirmKeyboard = (apartament: number) => {
    if (!apartament) {
      _pushToastMessage({
        type: "warning",
        text: "Ingrese un número de departamento / casa.",
        header: "Aviso",
      });
      return;
    }
    navigate(`/delivery-with-apartament/${apartament}`, { replace: true });
  };

  return (
    <div className="container-fluid h-100">
      <Header />
      <HiddenQrRreader />

      <Multimedia
        open={multimediaControl?.open}
        mediaData={multimediaControl?.mediaData}
        onClose={() =>
          setMultimediaControl({ ...multimediaControl, open: false })
        }
        priority={0}
      />

      <Drawer
        isOpen={deliveryDrawer.open}
        onClose={() => setDeliveryDrawer({ open: false, data: {} })}
      >
        <NumberKeyboard
          isOpen={deliveryDrawer.open}
          onConfirm={_handleOnConfirmKeyboard}
        />
      </Drawer>

      <div className="content-section">
        <div className="row justify-content-center mt-5">
          <div className="w-75 text-center">
            <h1 className="">
              <i></i>
            </h1>
          </div>
        </div>
        <div className="d-flex  justify-content-center mt-5 px-4">
          <div className="col-4 d-flex flex-column">
            <div className="mb-4" style={{ fontSize: "24px" }}>
              {assets?.events_home_pickup_list_title || (
                <>
                  ¿Como <b>solicito</b>?
                </>
              )}
            </div>
            <div className="px-2 size-09">
              {assets?.events_home_pickup_list?.map(
                (item: any, index: number) => (
                  <NumberList
                    number={index + 1}
                    key={`pickup-${index}`}
                    title={item.title}
                    text={item.description}
                    color={`rgba(${assets?.events_home_secondary_color?.r}, ${assets?.events_home_secondary_color?.g}, ${assets?.events_home_secondary_color?.b}, ${assets?.events_home_secondary_color?.a})`}
                  />
                )
              )}
            </div>
          </div>
          <div className="col-4 d-flex flex-column justify-content-center align-items-center">
            {company?.qr_code_image_path && (
              <>
                <div
                  className="bold mb-2 py-1 px-3 text-center"
                  style={{
                    background: "#000",
                    color: "#FFF",
                    borderRadius: "15px",
                    width: "200px",
                  }}
                >
                  Escanea el codigo
                </div>
                <img
                  src={company?.qr_code_image_path}
                  alt="qrcode"
                  className="p-0 m-0"
                  style={{
                    width: "200px",
                    borderRadius: "10px",
                    border: `8px solid rgba(${assets?.events_home_secondary_color?.r}, ${assets?.events_home_secondary_color?.g}, ${assets?.events_home_secondary_color?.b}, ${assets?.events_home_secondary_color?.a})`,
                  }}
                />
                <div className="mt-2">
                  <span
                    className="bold"
                    style={{
                      color: `rgba(${assets?.events_home_secondary_color?.r}, ${assets?.events_home_secondary_color?.g}, ${assets?.events_home_secondary_color?.b}, ${assets?.events_home_secondary_color?.a})`,
                    }}
                  >
                    QR
                  </span>{" "}
                  para registrarse
                </div>
              </>
            )}
          </div>
          <div className="col-4">
            <div className="mb-4" style={{ fontSize: "24px" }}>
              {assets?.events_home_delivery_list_title || (
                <>
                  ¿Como <b>retiro</b>?
                </>
              )}
            </div>
            <div className="px-2 size-09">
              {assets?.events_home_delivery_list?.map(
                (item: any, index: number) => (
                  <NumberList
                    number={index + 1}
                    key={`pickup-${index}`}
                    title={item.title}
                    text={item.description}
                    color={`rgba(${assets?.events_home_secondary_color?.r}, ${assets?.events_home_secondary_color?.g}, ${assets?.events_home_secondary_color?.b}, ${assets?.events_home_secondary_color?.a})`}
                  />
                )
              )}
            </div>
          </div>
        </div>
        <div className="w-100 text-center mt-5">
          <button
            className="px-5 py-2 main-dynamic-button bold"
            onClick={() => navigate("/my-box")}
          >
            {assets?.events_home_first_btn_text || "Mis Casilleros"}
          </button>
          {showMap && (
            <button
              className="px-5 py-2 main-dynamic-button bold"
              onClick={() =>
                setMultimediaControl({ ...multimediaControl, open: true })
              }
            >
              {assets?.events_home_third_btn_text || "Mapa del evento"}
            </button>
          )}
        </div>
        <div className="w-100 text-center mt-5">
          <button
            className="px-4 py-2 main-borderless-dynamic-button bold"
            onClick={() => navigate("/help-user/phone")}
            style={{
              color: "var(--events-home-btn-help-text-color)",
              background: "var(--events-home-btn-help-bg-color)",
            }}
          >
            {assets?.events_home_second_btn_text ||
              "¿No puedes retirar tus items? Presiona aquí y te llamaremos"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Home;
