/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Keyboard from "../../../../components/Keyboard";
import Logo from "../../../../components/Logo";
import { _pushToastMessage } from "../../../../helpers/messages";
import EventService from "../../../../services/events.service";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { set_loader } from "../../../../redux/actions/loader";

const ValidatePinCode = () => {
  const [state, setState] = useState<any>({
    data: "",
    redirect_to: "",
    keyboardLayout: {
      label: "ABC",
      value: "spanish_only_numbers",
    },
  });

  const keyboardRef = useRef<any>();
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const dispatch: any = useDispatch();
  const { session, order, pickup } = useSelector((state: any) => state);

  useEffect(() => {
    if (urlParams.has("redirect_to")) {
      setState({ ...state, redirect_to: urlParams.get("redirect_to") });
    }
  }, []);

  const _handleOnKeyPress = async (button: any) => {
    if (button === "{bksp}") {
      setState({ ...state, data: state.data.slice(0, -1) });
      return;
    }

    if (button === "{enter}") {
      if (state.data.length !== 4) {
        _pushToastMessage({
          type: "warning",
          text: "El pin debe contener 4 digitos",
          header: "Aviso",
        });
        return;
      }

      try {
        /**
         *  TODO: validate PIN
         */
        dispatch(set_loader({ is_loading: true }));
        await new EventService().validatePin({
          pin: state.data,
          terminal_id: session.profile?.id,
          delivery_user_id: order?.user?.id,
          reservation_id: pickup?.reservation?.id,
          target: state.redirect_to,
        });
        dispatch(set_loader({ is_loading: false }));
        navigate(`/${state.redirect_to}`, { replace: true });
      } catch (e) {
        dispatch(set_loader({ is_loading: false }));
        _pushToastMessage({
          type: "error",
          text: "El pin ingresado es incorrecto",
          header: "Aviso",
        });
      }

      return;
    }

    if (state.data.length >= 4) return;

    setState({ ...state, data: state.data + button });
  };

  const _handleOnChange = (value: any) => {
    console.log("ONCHANGE", value);
  };

  return (
    <div className="container-fluid h-100">
      <div className="w-100 text-center my-4">
        <Logo style={{ width: "100px" }} />
      </div>
      <div className="d-flex justify-content-center align-items-center p-0">
        <div
          className=" d-flex align-items-center justify-content-around flex-column p-5"
          style={{
            background: "rgba(255, 255, 255, 0.1)",
            width: "90%",
            borderRadius: "30px",
            height: "650px",
          }}
        >
          <div className="w-100 text-center bold size-14">
            Ingresa tu codigo PIN
          </div>

          <div>
            <div
              className="w-100 d-flex justify-content-center align-items-end my-4 pb-2"
              style={{
                color: "#000",
                background: "rgba(255, 255, 255, 0.1)",
                borderRadius: "10px",
                minWidth: "300px",
                minHeight: "80px",
              }}
            >
              {state.data?.split("").map((item: any, index: any) => {
                if (index === 4) {
                  return (
                    <>
                      <div
                        className="bold px-2 mx-2 py-0 my-0"
                        style={{
                          fontSize: "38px",
                          borderBottom: "4px solid #FFF",
                          color: "#FFF",
                        }}
                        key={index}
                      >
                        {item}
                      </div>
                      <div
                        className="bold px-2 mx-2 py-0 my-0"
                        style={{
                          fontSize: "38px",
                          borderBottom: "4px solid #FFF",
                          color: "#FFF",
                        }}
                        key={"-"}
                      >
                        -
                      </div>
                    </>
                  );
                }

                return (
                  <div
                    className="bold px-2 mx-2 py-0 my-0"
                    style={{
                      fontSize: "38px",
                      borderBottom: "4px solid #FFF",
                      color: "#FFF",
                    }}
                    key={index}
                  >
                    {item}
                  </div>
                );
              })}
            </div>
          </div>

          <div
            className="w-100 keyboard-number"
            style={{ color: "#000", height: "65vh" }}
          >
            <Keyboard
              onKeyPress={_handleOnKeyPress}
              onChange={_handleOnChange}
              keyboardRef={keyboardRef}
              layoutType={state.keyboardLayout?.value}
            />
          </div>

          <div className="text-center my-3">
            <button
              className="main-button px-5 py-2 ms-3 bold"
              onClick={() => navigate("/")}
            >
              Volver
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ValidatePinCode;
