/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef } from "react";
import { io } from "socket.io-client";
import { useSelector } from "react-redux";
import { debounce } from "lodash";

const HiddenQrRreaderFree = ({ onChange }: { onChange: any }) => {
  const { session } = useSelector((state: any) => state);

  const inputRef: any = useRef<any>();
  const debouncedSave = useRef(debounce(nextValue => _handleData(nextValue), 700))
		.current;

  /**
   * Focus input
   * clear interval
   * @returns void
   */
  useEffect(() => {
    const interval: any = setInterval(() => {
      inputRef.current.focus();
    }, 100);

    if (session.sign_in_component) {
      clearInterval(interval);
    }

    return () => {
      clearInterval(interval);
    };
  }, [session]);

  /**
   * Socket connection
   */
  useEffect(() => {
    const socket: any = io("http://127.0.0.1:3333");
    socket.on("news", (data: any) => {
      console.log(data);
    });

    socket.on("code", (data: any) => {
      const code: any = data.replace(/[\r\n]/gm, "");
      onChange(code);
      inputRef.current.value = "";
    });

    socket.on("connect_error", () => console.log("CONECT ERROR"));
    socket.on("reconnect_error", () => console.log("RECONECT ERROR"));

    return () => {
      console.log("Destroy socket connection");
      socket.destroy();
    };
  }, []);

  const _handleData = (data: any) => {
    onChange(data);
    inputRef.current.value = "";
  }

  const _handleChangeInput = (e: any) => {
      debouncedSave(e?.target?.value);
  };

  return (
    <div style={{ position: "absolute", top: "-100px" }}>
      <input
        type="text"
        ref={inputRef}
        id="codetext"
        style={{ opacity: 1, color: "#000" }}
        onChange={_handleChangeInput}
      />
    </div>
  );
};

export default HiddenQrRreaderFree;
