import api from "./api";
import store from "../redux/store";

class EventService {
  constructor() {
    const { session } = store.getState();
    api.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${session.security_token}`;
  }

  searchUser = async (payload: any) => {
    try {
      const response = await api.get(
        `/terminal/events/search-user?rut=${payload.rut}&terminal_id=${payload.terminal_id}&action=${payload.action}`
      );
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  searchUserByQR = async (payload: any) => {
    try {
      const response = await api.get(
        `/terminal/events/search-user-qr?qrcode=${payload.qrcode}&terminal_id=${payload.terminal_id}&action=${payload.action}`
      );
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  init = async (payload: any) => {
    try {
      const response = await api.post(`/terminal/events/init`, payload);
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  putArticles = async (payload: any) => {
    try {
      const response = await api.post(`/terminal/events/put-article`, payload);
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  confirmOrder = async (payload: any) => {
    try {
      const response = await api.post(
        `/terminal/events/confirm-order`,
        payload
      );
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  finishOrder = async (payload: any) => {
    try {
      const response = await api.post(`/terminal/events/finish-order`, payload);
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  sendHelpUserMessage = async (payload: any) => {
    try {
      const response = await api.post(`/terminal/events/help-user`, payload);
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  validatePin = async (payload: any) => {
    try {
      const response = await api.post(`/terminal/events/validate-pin`, payload);
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };
}

export default EventService;
