/* eslint-disable react-hooks/exhaustive-deps */
import Header from "../../components/Header";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { set_loader } from "../../../../redux/actions/loader";
import HiddenQrRreader from "../../components/HiddenQrReader";
import Drawer from "../../../../components/Drawer";
import NumberKeyboard from "../../components/NumberKeyboard";
import { useNavigate } from "react-router-dom";
import { _pushToastMessage } from "../../../../helpers/messages";
import NumberList from "../../../../components/NumberList";
import { useSelector } from "react-redux";

const Home = () => {
  const [deliveryDrawer, setDeliveryDrawer] = useState({
    open: false,
    data: {},
  });

  const navigate = useNavigate();
  const dispatch: any = useDispatch();
  const { session } = useSelector((state: any) => state);
  const { company } = session;

  useEffect(() => {
    const timeout: any = setTimeout(() => {
      dispatch(set_loader({ is_loading: false }));
      console.log("CLOSE LOADER");
    }, 5000);

    return () => {
      clearInterval(timeout);
      console.log("CLEAR INTERVAL");
    };
  }, []);

  const _handleOnConfirmKeyboard = (apartament: number) => {
    if (!apartament) {
      _pushToastMessage({
        type: "warning",
        text: "Ingrese un número de departamento / casa.",
        header: "Aviso",
      });
      return;
    }
    navigate(`/delivery-with-apartament/${apartament}`, { replace: true });
  };

  return (
    <div className="container-fluid h-100">
      <Header />
      <HiddenQrRreader />
      <Drawer
        isOpen={deliveryDrawer.open}
        onClose={() => setDeliveryDrawer({ open: false, data: {} })}
      >
        <NumberKeyboard
          isOpen={deliveryDrawer.open}
          onConfirm={_handleOnConfirmKeyboard}
        />
      </Drawer>

      <div className="content-section">
        <div className="row justify-content-center mt-5">
          <div className="w-75 text-center">
            <h1 className="">
              <i></i>
            </h1>
          </div>
        </div>
        <div className="d-flex  justify-content-center mt-5 px-4">
          <div className="col-4 d-flex flex-column">
            <div className="mb-4" style={{ fontSize: "24px" }}>
              ¿Como <b>guardo</b>?
            </div>
            <div className="px-2 size-09">
              <NumberList
                color="#FF0"
                number={1}
                title="Luego de registrarse, escanea "
                text="el QR de tu cedula de identidad"
              />
              <NumberList
                color="#FF0"
                number={2}
                title="Ingresa tu codigo PIN"
                text="Te lo enviamos por correo"
              />
              <NumberList
                color="#FF0"
                number={3}
                title="Selecciona un tamaño de caja"
                text=""
              />
              <NumberList
                color="#FF0"
                number={4}
                title="Guarda tus pertenencias"
                text="y cierra la puerta"
              />

              <NumberList
                color="#FF0"
                number={5}
                title="Puedes guardar y sacar"
                text="cosas las veces que quieras presionando en Mi Casillero ;)"
              />
            </div>
          </div>
          <div className="col-4 d-flex flex-column justify-content-center align-items-center">
            {company?.qr_code_image_path && (
              <>
                <div
                  className="bold mb-2 py-1 px-3 text-center"
                  style={{
                    background: "#000",
                    color: "#FFF",
                    borderRadius: "15px",
                    width: "200px",
                  }}
                >
                  Escanea el codigo
                </div>
                <img
                  src={company?.qr_code_image_path}
                  alt="qrcode"
                  className="p-0 m-0"
                  style={{
                    width: "200px",
                    borderRadius: "10px",
                    border: "8px solid #FF0",
                  }}
                />
                <div className="mt-2">
                  <span className="bold" style={{ color: "#FF0" }}>
                    QR
                  </span>{" "}
                  para registrarse
                </div>
              </>
            )}
          </div>
          <div className="col-4">
            <div className="mb-4" style={{ fontSize: "24px" }}>
              ¿Como <b>retiro</b>?
            </div>
            <div className="px-2 size-09">
              <NumberList
                color="#FF0"
                number={1}
                title="Escanea tu cedula de identidad"
                text="para abrir tu casillero"
              />
              <NumberList
                color="#FF0"
                number={2}
                title="Ingresa tu codigo PIN"
                text="Te lo enviamos por correo"
              />
              <NumberList
                color="#FF0"
                number={3}
                title="Saca tus pertenencias"
                text="y cierra la puerta ;)"
              />
            </div>
          </div>
        </div>
        <div className="w-100 text-center mt-5">
          <button
            className="px-5 py-2 main-button-yellow bold"
            onClick={() => navigate("/my-box")}
          >
            Mi Casillero
          </button>
        </div>
        <div className="w-100 text-center mt-5">
          <button
            className="px-4 py-2 bold btn color-white border"
            onClick={() => navigate("/help-user/phone")}
          >
            ¿No puedes retirar tus items? Presiona aquí y te llamaremos
          </button>
        </div>
      </div>
    </div>
  );
};

export default Home;
