import Image from "../Image";
import boxImage from "../../assets/svg/box.svg";
import { Badge } from "rsuite";
import { useSelector } from "react-redux";
import { formatChilleanPhone } from "../../helpers/functions";
const CardOpenBoxDashboard = (props: any) => {
  const { door_number, status, data, _handleOpenBox, _handleOpenBoxAndCancel } =
    props;
  const { modules } = useSelector((state: any) => state);
  console.log(data);
  const styles = {
    card: {
      backgroundColor: "#FFF",
      borderRadius: "7px",
      maxWidth: "270px",
      minWidth: "250px",
    },
    imageWrapper: {
      backgroundColor: "rgb(255 255 160)",
      borderRadius: "50%",
      width: "45px",
      height: "45px",
    },
  };

  return (
    <div className={`p-2 col mx-2 my-3 shadow  d-flex flex-column justify-content-around`} style={styles.card}>
      <div className="d-flex justify-content-between align-items-center px-2 mt-2">
        <div>
          <div className="size-11 bold d-flex align-items-center">
            Caja {door_number}
            <Badge
              content={status}
              color={status === "Libre" ? "green" : "red"}
              className="ms-3"
              style={{ padding: "2px 15px", fontWeight: "bolder" }}
            />
          </div>
        </div>
        <div
          className="d-flex justify-content-center align-items-center"
          style={styles.imageWrapper}
        >
          <Image src={boxImage} style={{ width: "30px" }} />
        </div>
      </div>

      <div className="row my-1">
        <div className="col-12">
          {data?.valid_reservations?.length > 0 && (
            <div className="size-08 px-3 bold">
              {data?.valid_reservations?.map(
                (reservation: any, index: number) => (
                  <>
                    <div key={reservation.id}>
                      {reservation?.delivery_user?.name}
                    </div>
                    <div key={reservation.id}>
                      {formatChilleanPhone(reservation?.delivery_user?.phone)}
                    </div>
                  </>
                )
              )}
            </div>
          )}
        </div>
      </div>

      <div className="row mt-3 mb-3">
        <div className="col-12 d-flex justify-content-around">
          {modules?.module_selected?.indexOf("events") !== -1 ? (
            <>
              {data?.valid_reservations?.length > 0 ? (
                <>
                  <button
                    className="px-4 py-2 main-button-dashboard bold shadow border rounded size-08"
                    onClick={() => _handleOpenBox(data)}
                  >
                    ABRIR
                  </button>
                  <button
                    className="px-2 py-2 bold shadow border rounded size-08"
                    onClick={() => _handleOpenBoxAndCancel(data)}
                    style={{ backgroundColor: "#f44336", color: "#FFF" }}
                  >
                    ABRIR Y ELIMINAR
                  </button>
                </>
              ) : (
                <button
                  className="px-5 py-2 main-button-dashboard bold shadow border rounded"
                  onClick={() => _handleOpenBox(data)}
                >
                  ABRIR
                </button>
              )}
            </>
          ) : (
            <button
              className="px-5 py-2 main-button-dashboard bold shadow border rounded"
              onClick={() => _handleOpenBox(data)}
            >
              ABRIR
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default CardOpenBoxDashboard;
