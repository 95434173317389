import { useEffect } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import SignIn from "../components/SignIn";
import Boxes from "../modules/DynamicPickupBox/pages/Boxes";
import Companies from "../modules/DynamicPickupBox/pages/Companies";
import Delivery from "../modules/DynamicPickupBox/pages/Delivery";
import Home from "../modules/DynamicPickupBox/pages/Home";
import OpenBoxDelivery from "../modules/DynamicPickupBox/pages/OpenBoxDelivery";
import OpenBoxPickup from "../modules/DynamicPickupBox/pages/OpenBoxPickup";
import Pickup from "../modules/DynamicPickupBox/pages/Pickup";
import Success from "../modules/DynamicPickupBox/pages/Success";
import DashboardRouter from "./DashboardRouter";


export default function DynamicPickupBoxRouter() {
  const { session } = useSelector((state: any) => state);

  useEffect(() => {
    import(`../modules/DynamicPickupBox/styles/_global_pickup.scss`);
  }, []);

  return (
    <BrowserRouter>
      <SignIn open={session.sign_in_component} />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/delivery" element={<Delivery />} />
        <Route path="/available-boxes" element={<Boxes />} />
        <Route path="/companies" element={<Companies />} />
        <Route path="/open-box-delivery" element={<OpenBoxDelivery />} />
        <Route path="/open-box-pickup" element={<OpenBoxPickup />} />
        <Route path="/success" element={<Success />} />
        <Route path="/pickup" element={<Pickup />} />
        <Route path="/dashboard/*" element={<DashboardRouter />} />
      </Routes>
    </BrowserRouter>
  );
}
