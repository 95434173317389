import api from "./api";
import store from "../redux/store";

class LendingService {
  constructor() {
    const { session } = store.getState();
    api.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${session.security_token}`;
  }

  saveArticles = async (payload: any) => {
    try {
      const response = await api.post(
        `/terminal/lending/load-article`,
        payload
      );
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  getLendingByArticles = async (payload: any) => {
    try {
      const response = await api.get(
        `/terminal/lending/check-code-delivery?terminal_id=${payload.terminal_id}&${payload.codes}`,
        payload
      );
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  delivery = async (payload: any) => {
    try {
      const response = await api.post(
        `/terminal/lending/delivery-articles`,
        payload
      );
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  getLendingByDocument = async (payload: any) => {
    try {
      const response = await api.get(
        `/terminal/lending/check-code?terminal_id=${payload.terminal_id}&code=${payload.code}`
      );
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  pickup = async (payload: any) => {
    try {
      const response = await api.post(
        `/terminal/lending/pickup-articles`,
        payload
      );
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  getAvailableArticles = async (payload: any) => {
    try {
      const response = await api.get(
        `/terminal/lending/get-availables-articles?terminal_id=${payload.terminal_id}&is_accessible=${payload.is_accessible}`
      );
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  getAvailableArticlesTerminal = async (payload: any) => {
    try {
      const response = await api.get(
        `/terminal/lending/get-availables-articles-terminal?terminal_id=${payload.terminal_id}&is_accessible=${payload.is_accessible}`
      );
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  deleteLending = async (payload: any) => {
    try {
      const response = await api.post(
        `/terminal/lending/delete-articles`,
        payload
      );
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };
}

export default LendingService;
