/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Loader from "../components/Loader";
import LiderRouter from "./LiderRouter";
import PickupBoxRouter from "./PickupBoxRouter";
import PickupRouter from "./PickupRouter";
import ResidentialRouter from "./ResidentialRouter";
import UnimarcRouter from "./UnimarcRouter";
import LasCondesRouter from "./LasCondesRouter";
import ResidentialCuboRouter from "./ResidentialCuboRouter";
import ServicesRouter from "./ServicesRouter";
import { useDispatch } from "react-redux";
import { SET_TIMER } from "../redux/actions/timer";
import LendingRouter from "./LendingRouter";
import ModuleNotFound from "../components/ModuleNotFound";
import ResidentialOfflineRouter from "./ResidentialOfflineRouter";
import ModuleVersion from "../components/ModuleVersion";
import LendingUnabRouter from "./LendingUnabRouter";
import AutoUpdate from "../components/AutoUpdate";
import DynamicResidentialRouter from "./DynamicResidentialRouter";
import DynamicResidentialOfflineRouter from "./DynamicResidentialOfflineRouter";
import DynamicPickupRouter from "./DynamicPickupRouter";
import DynamicPickupBoxRouter from "./DynamicPickupBoxRouter";
import DynamicLendingRouter from "./DynamicLendingRouter";
import EventsRouter from "./EventsRouter";
import DynamicEventsRouter from "./DynamicEventsRouter";

/**
 *
 * @modules
 *
 * residential-module: Modulo de entrega residencial.
 *    Primero se crea un usuario, y este usuario queda disponible para ser usado en el modulo de entrega residencial.
 *
 * pickup-module: Modulo de entrega con caja reservada
 *    Al momento de crear la orden, se inicia una reserva, crea el usuario y hace la reserva de la(s) caja(s).
 *
 * pickup-box-module: Modulo de entrega sin caja reservada
 *    Al momento de crear la orden, se inicia una reserva, crea el usuario, pero no hace la reserva de una o mas cajas.
 *    Se selecciona la caja al momento de hacer la entrega
 *
 * lider-module:
 *    Mismo que modulo `pickup-module`, pero con la diferencia que tiene el template de lider.
 *
 * unimarc-module:
 *    Mismo que modulo `pickup-module`, pero con la diferencia que tiene el template de unimarc.
 *
 * lascondes-module:
 *    Mismo que modulo `pickup-module`, pero con la diferencia que tiene el template de la municipalidad las condes.
 */

const RouterWrapper = () => {
  const Routers: any = {
    "residential-module": ResidentialRouter,
    "residential-offline-module": ResidentialOfflineRouter,
    "pickup-module": PickupRouter,
    "pickup-box-module": PickupBoxRouter,
    "lider-module": LiderRouter,
    "unimarc-module": UnimarcRouter,
    "lascondes-module": LasCondesRouter,
    "residential-cubo-module": ResidentialCuboRouter,
    "only-services-module": ServicesRouter,
    "lending-module": LendingRouter,
    "lending-unab-module": LendingUnabRouter,
    "dynamic-residential-module": DynamicResidentialRouter,
    "dynamic-residential-offline-module": DynamicResidentialOfflineRouter,
    "dynamic-pickup-module": DynamicPickupRouter,
    "dynamic-pickup-box-module": DynamicPickupBoxRouter,
    "dynamic-lending-module": DynamicLendingRouter,
    "dynamic-events-module": DynamicEventsRouter,
    "events-module": EventsRouter,

  };

  const { modules } = useSelector((state: any) => state);
  const dispatch: any = useDispatch();

  const [state, setState] = useState({
    Router: Routers[modules.module_selected],
  });

  useEffect(() => {
    dispatch(SET_TIMER({ seconds: process.env.REACT_APP_TIMER_SECONDS || 25 }));
    try {
      let Router = Routers[modules.module_selected];
      if (Router) {
        setState({ ...state, Router });
      }
    } catch (e) {
      alert("Error al cargar el modulo seleccionado");
    }
  }, [modules.module_selected]);

  if(state.Router === undefined) return (<ModuleNotFound />);

  return (
    <>
      <Loader />
      <AutoUpdate />
      <ModuleVersion module={modules.module_selected} version="1.0.0" showVersion={false} />
      <state.Router />
    </>
  );
};

export default RouterWrapper;
