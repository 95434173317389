/* eslint-disable react-hooks/exhaustive-deps */
import { useSelector } from "react-redux";
import Header from "../../components/Header";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { handleAllColorStyles } from "../../../../helpers/dynamic_styles";

const HelpUserSuccess = () => {
  const navigate = useNavigate();
  const { session } = useSelector((state: any) => state);
  const assets = session?.profile?.assets?.assets;

  useEffect(() => {
    handleAllColorStyles(assets);
  }, []);

  return (
    <div className="container-fluid h-100">
      <Header />
      <div className="d-flex justify-content-center align-items-center p-0">
        <div
          className="d-flex align-items-center justify-content-around flex-column p-5"
          style={{
            background: "var(--events-default-backdrop-color)",
            width: "90%",
            borderRadius: "30px",
            height: "650px",
          }}
        >
          <div
            className="d-flex flex-column align-items-center bold text-center mt-5"
            style={{ textTransform: "uppercase", fontSize: "2rem" }}
          >
            {assets?.events_help_success_text ? (
              <>{assets?.events_help_success_text} </>
            ) : (
              <>
                solicitud completada con éxito. <br />
                Te llamaremos pronto.
              </>
            )}
          </div>

          <div className="text-center">
            <button
              className="px-5 py-2 main-borderless-dynamic-button bold"
              onClick={() => navigate("/")}
              style={{ textTransform: "uppercase" }}
            >
              Volver al home
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HelpUserSuccess;
