/* eslint-disable react-hooks/exhaustive-deps */
import Header from "../../components/Header";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { set_loader } from "../../../../redux/actions/loader";
import Drawer from "../../../../components/Drawer";
import NumberKeyboard from "../../components/NumberKeyboard";
import { useNavigate } from "react-router-dom";
import { _pushToastMessage } from "../../../../helpers/messages";
import NumberList from "../../../../components/NumberList";
import HiddenQrRreaderMyBox from "../../components/HiddenQrReaderMyBox";
import { useSelector } from "react-redux";
import { handleAllColorStyles } from "../../../../helpers/dynamic_styles";

const MyBox = () => {
  const [deliveryDrawer, setDeliveryDrawer] = useState({
    open: false,
    data: {},
  });
  const { session } = useSelector((state: any) => state);
  const assets = session?.profile?.assets?.assets;

  const navigate = useNavigate();

  const dispatch: any = useDispatch();
  useEffect(() => {
    handleAllColorStyles(assets);
    const timeout: any = setTimeout(() => {
      dispatch(set_loader({ is_loading: false }));
      console.log("CLOSE LOADER");
    }, 5000);

    return () => {
      clearInterval(timeout);
      console.log("CLEAR INTERVAL");
    };
  }, []);

  const _handleOnConfirmKeyboard = (apartament: number) => {
    if (!apartament) {
      _pushToastMessage({
        type: "warning",
        text: "Ingrese un número de departamento / casa.",
        header: "Aviso",
      });
      return;
    }
    navigate(`/delivery-with-apartament/${apartament}`, { replace: true });
  };

  return (
    <div className="container-fluid h-100">
      <Header />
      <HiddenQrRreaderMyBox />
      <Drawer
        isOpen={deliveryDrawer.open}
        onClose={() => setDeliveryDrawer({ open: false, data: {} })}
      >
        <NumberKeyboard
          isOpen={deliveryDrawer.open}
          onConfirm={_handleOnConfirmKeyboard}
        />
      </Drawer>

      <div className="content-section">
        <div className="row justify-content-center mt-5">
          <div className="w-75 text-center">
            <h1 className="">
              <i></i>
            </h1>
          </div>
        </div>
        <div className="d-flex  justify-content-center mt-5 px-4">
          <div className="col-6 d-flex flex-column">
            {assets.events_my_locker_list_title ? (
              <div className="mb-4" style={{ fontSize: "24px" }}>
                {assets.events_my_locker_list_title}
              </div>
            ) : (
              <div className="mb-4" style={{ fontSize: "24px" }}>
                ¿Como <b>guardo</b> o <b>saco</b> cosas de mi casillero?
              </div>
            )}
            <div className="px-2 size-09">
              {assets?.events_my_locker_list?.map(
                (item: any, index: number) => (
                  <NumberList
                    number={index + 1}
                    key={`pickup-${index}`}
                    title={item.title}
                    text={item.description}
                    color={`rgba(${assets?.events_home_secondary_color?.r}, ${assets?.events_home_secondary_color?.g}, ${assets?.events_home_secondary_color?.b}, ${assets?.events_home_secondary_color?.a})`}
                  />
                )
              )}
            </div>
          </div>
        </div>

        <div className="w-100 text-center mt-5">
          <button
            className="px-5 py-2 main-dynamic-button bold"
            onClick={() => navigate("/")}
          >
            Volver
          </button>
        </div>
        <div className="w-100 text-center mt-5">
          <button
            className="px-4 py-2 main-borderless-dynamic-button bold"
            onClick={() => navigate("/help-user/phone")}
            style={{
              color: "var(--events-home-btn-help-text-color)",
              background: "var(--events-home-btn-help-bg-color)",
            }}
          >
            {assets?.events_home_second_btn_text ||
              "¿No puedes retirar tus items? Presiona aquí y te llamaremos"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default MyBox;
