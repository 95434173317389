import Image from "../../../../components/Image";
import Header from "../../components/Header";
import checked from "../../../../assets/svg/checked.svg";
import { useNavigate } from "react-router-dom";
const HelpUserSuccess = () => {
  const navigate = useNavigate();

  return (
    <div className="container-fluid h-100">
      <Header />
      <div className="d-flex justify-content-center align-items-center p-0">
        <div
          className="d-flex align-items-center justify-content-around flex-column p-5"
          style={{
            background: "rgba(255, 255, 255, 0.1)",
            width: "90%",
            borderRadius: "30px",
            height: "650px",
          }}
        >
          <div
            className="d-flex flex-column align-items-center bold text-center mt-5"
            style={{ textTransform: "uppercase", fontSize: "2rem" }}
          >
            <Image
              src={checked}
              style={{ width: "100px", marginBottom: "30px" }}
            />
            solicitud completada con éxito. <br />
            Te llamaremos pronto.
          </div>

          <div className="text-center">
            <button
              className="px-4 py-2 main-button bold"
              onClick={() => navigate("/")}
              style={{ textTransform: "uppercase" }}
            >
              Volver al home
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HelpUserSuccess;
