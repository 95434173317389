/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate, useSearchParams } from "react-router-dom";
import Header from "../../components/Header";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { set_loader } from "../../../../redux/actions/loader";
import { _pushToastMessage } from "../../../../helpers/messages";
import { clear_codes, set_codes } from "../../../../redux/actions/codes";
import { useSelector } from "react-redux";
import LendingService from "../../../../services/lending.service";
import { set_boxes } from "../../../../redux/actions/boxes";
import HiddenQrRreaderFree from "../../components/HiddenQrReaderFree";
import { ReactComponent as Checked } from "../../../../assets/svg/lending_checked.svg";
import { ReactComponent as CheckedTrue } from "../../../../assets/svg/lending_checked_green.svg";
import { debounce } from "lodash";
const { v4: uuidv4 } = require("uuid");

const DeliveryArticle = () => {
  const [codes, setCodes] = useState<
    { id: string; code: string; isValid: boolean }[]
  >([]);

  const navigate = useNavigate();
  const dispatch: any = useDispatch();
  const [searchParams] = useSearchParams();
  const { codes: reduxCodes, session } = useSelector((state: any) => state);

  const Lending = new LendingService();

  const debouncedSave = useRef(debounce((url) => navigate(url), 60000)).current;

  /**
   * Add code to list
   */
  useEffect(() => {
    _handleAddCode();

    return () => {
      debouncedSave.cancel();
    };
  }, []);

  const _handleAddCode = async () => {
    if (!searchParams.has("add") && !searchParams.has("action")) {
      dispatch(clear_codes());
      dispatch(set_loader({ is_loading: false }));
      debouncedSave("/");
      return;
    }

    if (searchParams.has("add")) {
      setCodes([...reduxCodes.codes]);
      validateCodes({
        code: searchParams.get("add") || "",
        codesList: reduxCodes.codes.length ? reduxCodes.codes : null,
      });
      return;
    }
    setCodes([...reduxCodes.codes]);
  };

  const _handleOnChangeQrReaderFree = async (data: any) => {
    const code: any = data.replace(/[\r\n]/gm, "");
    const finalCode = code.replace(/[^a-zA-Z0-9]/g, "");
    validateCodes({ code: finalCode, codesList: null });
  };

  const validateCodes = async ({
    code,
    codesList,
  }: {
    code: string;
    codesList: any | null;
  }) => {
    try {
      let codes: any = [];
      if (codesList) {
        codes = codesList;
      }

      if (!codesList) {
        setCodes((prevState: any) => {
          codes = [...prevState];
          return [...prevState];
        });
      }

      if (codes.length === 0) {
        dispatch(set_loader({ is_loading: true }));
        const response: any = await Lending.getLendingByArticles({
          codes: `codes[]=${code}`,
          terminal_id: session.profile.id,
        });

        const items =
          response.data?.reservation?.boxes[0]?.packages[0]?.attributes?.items;

        const articles = response.data?.articles;
        let articleNumber = 1;

        codes = items.map((item: any) => {
          let name = `Articulo ${articleNumber}`;

          if (articles.find((a: any) => a.code === item.code)) {
            name = articles.find((a: any) => a.code === item.code).name;
          } else {
            articleNumber++;
          }

          return {
            id: uuidv4(),
            code: item.code,
            isValid: item.code === code ? true : false,
            name: name,
          };
        });

        codes.sort((a: any, b: any) => {
          if (a.isValid) return -1;
          if (!a.isValid) return 1;
          return 0;
        });

        setCodes([...codes]);
        dispatch(set_codes({ codes: [...codes] }));
        dispatch(set_loader({ is_loading: false }));
      }

      if (codes.length > 0) {
        const thisCodeIsValid = codes.find((c: any) => c.code === code);
        if (thisCodeIsValid) {
          thisCodeIsValid.isValid = true;
          codes.sort((a: any, b: any) => {
            if (a.isValid) return -1;
            if (!a.isValid) return 1;
            return 0;
          });
          setCodes(() => {
            return [...codes];
          });
          dispatch(set_codes({ codes: [...codes] }));
        } else {
          _pushToastMessage({
            text: "El código informado no es válido",
            type: "error",
            header: "Error",
          });
        }
      }

      const validatedCodes = codes.filter((c: any) => c.isValid);
      if (validatedCodes.length === codes.length) {
        handleDeliveryArticles(codes);
      }
    } catch (e: any) {
      dispatch(set_loader({ is_loading: false }));
      _pushToastMessage({
        text:
          e?.response?.data?.message ||
          e?.message ||
          "Error al validar el código",
        type: "error",
        header: "Error",
      });
      navigate("/");
    }
  };

  const handleDeliveryArticles = async (codes: any) => {
    const items = codes.map((code: any) => {
      return `codes[]=${code.code}`;
    });

    try {
      dispatch(set_loader({ is_loading: true }));
      const response: any = await Lending.getLendingByArticles({
        codes: items.join("&"),
        terminal_id: session.profile.id,
      });

      const boxes = response.data?.reservation?.boxes;
      boxes[0].message_data = response.data?.data;

      const reservationInfo = response.data?.data;

      if (reservationInfo?.exceeded_second_mark?.is_exceeded) {
        dispatch(set_boxes({ box_list: boxes }));
        dispatch(set_loader({ is_loading: false }));
        navigate("/load-article-failed");
        return;
      }

      if (boxes.length === 0) {
        _pushToastMessage({
          text: "No se encontraron cajas para guardar los artículos",
          type: "error",
          header: "Error",
        });
        dispatch(set_loader({ is_loading: false }));
        return;
      }

      await Lending.delivery({
        terminal_id: session.profile.id,
        reservation_id: response.data?.reservation.id,
      });

      dispatch(set_boxes({ box_list: boxes }));
      dispatch(set_loader({ is_loading: false }));
      navigate("/load-article-user-success");
    } catch (e: any) {
      dispatch(set_loader({ is_loading: false }));
    }
  };

  return (
    <div className="container-fluid h-100">
      <Header />
      <HiddenQrRreaderFree onChange={_handleOnChangeQrReaderFree} />
      <div className="row">
        <div
          className="col-12 px-5 mb-4"
          style={{ height: "550px", overflow: "auto" }}
        >
          <div className="w-100 text-center bold size-14">
            ESCANEA O INGRESA EL CODIGO DE LOS ARTICULOS
          </div>

          <div className="w-100 d-flex mt-5" style={{ flexWrap: "wrap" }}>
            <div
              style={{ borderBottom: "1px solid #FFF" }}
              className="w-50 text-center bold text-12"
            >
              ITEM
            </div>
            <div
              style={{ borderBottom: "1px solid #FFF" }}
              className="w-50 text-center bold text-12"
            >
              ESCANEADO
            </div>

            {codes?.map((code: any) => (
              <div
                className="w-100 d-flex align-items-center"
                style={{ borderBottom: "1px solid #FFF" }}
                key={code.id}
              >
                <div
                  className="w-50 text-center bold text-11 my-3"
                  style={{ color: code.isValid ? "#fff" : "#333" }}
                >
                  {code.name}
                </div>
                <div className="w-50 text-center bold text-11 my-3">
                  {code.isValid ? (
                    <CheckedTrue style={{ width: "40px", height: "40px" }} />
                  ) : (
                    <Checked style={{ width: "40px", height: "40px" }} />
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="col-12 text-center mb-3">
          <button
            className="main-button border-0 px-5 py-3 ms-3 bold"
            onClick={() => navigate("/")}
          >
            Volver
          </button>
          <button
            className="main-button border-0 px-5 py-3 ms-3 bold"
            onClick={() => navigate("/insert-code?item=delivery-article")}
          >
            Ingresar código manual
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeliveryArticle;
