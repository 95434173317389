/* eslint-disable react-hooks/exhaustive-deps */
import Header from "../../components/Header";
// import { ReactComponent as Delivery } from "../../../../assets/svg/package-box.svg";
// import ItemAction from "../../components/ItemAction";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { set_loader } from "../../../../redux/actions/loader";
import HiddenQrRreader from "../../components/HiddenQrReader";
import Drawer from "../../../../components/Drawer";
import NumberKeyboard from "../../components/NumberKeyboard";
import { useNavigate } from "react-router-dom";
import { _pushToastMessage } from "../../../../helpers/messages";
import NumberList from "../../../../components/NumberList";

const Home = () => {
  const [deliveryDrawer, setDeliveryDrawer] = useState({
    open: false,
    data: {},
  });

  const navigate = useNavigate();

  const dispatch: any = useDispatch();
  useEffect(() => {
    const timeout: any = setTimeout(() => {
      dispatch(set_loader({ is_loading: false }));
      console.log("CLOSE LOADER");
    }, 5000);

    return () => {
      clearInterval(timeout);
      console.log("CLEAR INTERVAL");
    };
  }, []);

  const _handleOnConfirmKeyboard = (apartament: number) => {
    if (!apartament) {
      _pushToastMessage({
        type: "warning",
        text: "Ingrese un número de departamento / casa.",
        header: "Aviso",
      });
      return;
    }
    navigate(`/delivery-with-apartament/${apartament}`, { replace: true });
  };

  return (
    <div className="container-fluid h-100">
      <Header />
      <HiddenQrRreader />
      <Drawer
        isOpen={deliveryDrawer.open}
        onClose={() => setDeliveryDrawer({ open: false, data: {} })}
      >
        <NumberKeyboard
          isOpen={deliveryDrawer.open}
          onConfirm={_handleOnConfirmKeyboard}
        />
      </Drawer>

      <div className="content-section">
        <div
          className="row justify-content-center my-5 align-items-center"
          style={{ height: "400px" }}
        >
          <div className="d-flex justify-content-center mt-5 py-5 px-4">
            <div className="col-6 d-flex flex-column px-5">
              <div className="mb-4" style={{ fontSize: "24px" }}>
                ¿Como <b>solicito</b>?
              </div>
              <div className="px-2 size-09">
                <NumberList
                  number={1}
                  title="Realiza tu reserva"
                  text=""
                  color="#FF0"
                />
                <NumberList
                  number={2}
                  title="Acercate al Autopréstamo"
                  text="Escanea el QR de tu carnet de identidad para realizar el préstamo"
                  color="#FF0"
                />
                <NumberList
                  number={3}
                  title="Retira tus articulos"
                  text="del casillero asignado ;)"
                  color="#FF0"
                />
              </div>
            </div>
            
            <div className="col-6 px-5">
              <div className="mb-4" style={{ fontSize: "24px" }}>
                ¿Como <b>devuelvo</b>?
              </div>
              <div className="px-2 size-09">
                <NumberList
                  number={1}
                  title="Acércate al Autopréstamo"
                  text=""
                  color="#FF0"
                />
                <NumberList
                  number={2}
                  title="Escanea el código"
                  text="de los articulos que solicitaste"
                  color="#FF0"
                />
                <NumberList
                  number={3}
                  title="Automaticamente el Autopréstamo"
                  text="te asignará un casillero"
                  color="#FF0"
                />
                <NumberList
                  number={4}
                  title="Guarda los articulos"
                  text="en el casillero asignado."
                  color="#FF0"
                />
                <NumberList
                  number={5}
                  title="¡Recuerda cerrar el casillero! ;)"
                  text=""
                  color="#FF0"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
