/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { set_loader } from "../../../../redux/actions/loader";
import DeliveryUserService from "../../../../services/delivery_user.service";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { _pushToastMessage } from "../../../../helpers/messages";
import Header from "../../components/Header";
import { ReactComponent as User } from "../../../../assets/svg/user.svg";
import { ReactComponent as Circle } from "../../../../assets/svg/circle.svg";

import "./component.apartment.scss";
import { set_order } from "../../../../redux/actions/order";

const Apartaments = () => {
  const [state, setState] = useState<any>({
    users: [],
    selectedUser: null,
  });

  const { session, loader } = useSelector((state: any) => state);
  const params: any = useParams();
  const dispatch: any = useDispatch();
  const DeliveryUsers = new DeliveryUserService();
  const navigate = useNavigate();
  const assets = session.profile?.assets?.assets;

  useEffect(() => {
    _getUsers();
  }, []);

  const isNumber = (value: string) => {
    const regex = /^[0-9]+$/;
    return regex.test(value);
  };

  const _getUsers = async () => {
    dispatch(set_loader({ is_loading: true }));
    try {
      const response: any = await DeliveryUsers.filter_users({
        terminal_id: session.profile?.id,
        params: { apartment: params.apartment },
      });
      const { delivery_users } = response.data;
      let users = delivery_users.filter((user: any) => {
        let apartment = user.apartment;

        if (!apartment) return false;

        /* REMOVE SPACES */
        apartment = apartment.replace(/ /g, "");

        /* GET QUERY POSITION INTO STRING */
        const position = apartment
          ?.toLowerCase()
          .indexOf(params.apartment?.toLowerCase());

        /* IF QUERY NOT FOUND */
        if (position === -1) return false;

        /* GET NEXT AND BEFORE CHAR */
        const nextChar = apartment[position + params.apartment.length];

        const beforeChar = apartment[position - 1];

        /* IF NEXT AND BEFORE CHAR ARE EMPTY */
        if (!beforeChar && !nextChar) return true;

        /* IF NOT EXISTS A CHAR BEFORE, CHECK IF NEXT CHAR EXISTS AND IF IS A NUMBER */
        if (!beforeChar) {
          if (isNumber(nextChar)) return false;
        }

        /* IF NOT EXISTS A CHAR NEXT, CHECK IF BEFORE CHAR EXISTS AND IF IS A NUMBER */
        if (!nextChar) {
          if (isNumber(beforeChar)) return false;
        }

        /* IF EXISTS A CHAR BEFORE AND NEXT, CHECK IF ONE OF THEM ARE NUMBERS */
        if (beforeChar && nextChar) {
          if (isNumber(beforeChar) || isNumber(nextChar)) return false;
        }

        return true;
      });

      if (users.length === 0) {
        _pushToastMessage({
          type: "warning",
          text: "No hay residentes en este departamento",
          header: "Aviso",
        });
        navigate("/");
      }

      users.sort((a: any, b: any) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      });

      setState({ ...state, users: users || [] });
      dispatch(set_loader({ is_loading: false }));
    } catch (e: any) {
      _pushToastMessage({
        type: "warning",
        text: "No fue posible cargar los usuarios",
        header: "Aviso",
      });
      dispatch(set_loader({ is_loading: false }));
    }
  };

  const _handleSelectUser = (user: any) => {
    console.log(user);
    dispatch(set_order({ user: user }));
    navigate(`/open-box-delivery/${params.apartment}`);
  };

  const UserInfoCard = ({
    user,
    key,
    style = {
      width: "430px",
      backgroundColor: "rgba(255, 255, 255, 0.2)",
      borderRadius: "10px",
      position: "relative",
    },
    nameStyle = {
      width: "100%",
    },
  }: any) => {
    return (
      <div
        className="py-4 px-2 mb-3 uppercase d-flex justify-content-start bold"
        style={style}
        key={key}
        onClick={() => _handleSelectUser(user)}
      >
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ width: "100px", position: "relative" }}
        >
          <Circle
            style={{ position: "absolute", width: "60px", height: "60px" }}
          />
          <User
            style={{ position: "absolute", width: "40px", height: "40px" }}
          />
        </div>
        <div className="d-flex flex-column justify-content-center">
          <div className="text-start" style={nameStyle}>
            {user.name}
          </div>
          <div className="text-start" style={{ width: "100%" }}>
            {user.apartment}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="container-fluid h-100 ">
      <Header />
      <div
        className="row align-items-center justify-content-center mt-3"
        style={{ height: "600px" }}
      >
        <div
          className="h-100 d-flex align-items-center justify-content-center flex-column"
          style={{
            background: "rgba(255, 255, 255, 0.1)",
            width: "90%",
            borderRadius: "30px",
          }}
        >
          {loader.is_loading && (
            <div className="row mt-5">
              <div className="col-12 text-center bold size-14">
                Buscando residentes...
              </div>
            </div>
          )}

          {!loader.is_loading && (
            <div className="row mt-5">
              <div className="col-12 text-center bold size-14">
                {assets?.delivery_step_two_text_one || "Selecciona el residente"}
              </div>
            </div>
          )}

          {state.users?.length > 0 && state.users.length < 4 && (
            <div
              className="d-flex mt-5 mb-2 w-100 align-items-center flex-column apartment px-1"
              style={{ maxHeight: "45vh", overflow: "auto" }}
            >
              {state.users.map((user: any, index: number) => (
                <UserInfoCard user={user} key={index} />
              ))}
            </div>
          )}

          {state.users?.length > 3 && (
            <div
              className="d-flex mt-5 mb-2 w-100 flex-wrap justify-content-around apartment px-2"
              style={{ maxHeight: "45vh", overflow: "auto" }}
            >
              {state.users.map((user: any, index: number) => (
                <UserInfoCard
                user={user}
                key={index}
                style={{
                  width: "390px",
                  backgroundColor: "rgba(255, 255, 255, 0.2)",
                  borderRadius: "10px",
                  position: "relative",
                }}
                nameStyle={{
                  width: "270px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              />
              ))}
            </div>
          )}

          <div className="row">
            <div className="col-12 text-center mt-4">
              <button
                className="px-4 py-2 main-button bold"
                onClick={() => navigate("/info-apartment")}
              >
                Volver
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Apartaments;
