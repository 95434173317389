/* eslint-disable react-hooks/exhaustive-deps */

import { useSelector } from "react-redux";
import CardOpenBoxResidential from "../CardOpenBoxResidential";

const BoxToOpenPickupMyBox = (props: any) => {
  const { action } = props;
  const { pickup, session } = useSelector((state: any) => state);
  const { boxes } = pickup;

  const assets = session?.profile?.assets?.assets;

  return (
    <div className="d-flex justify-content-center align-items-center mt-2">
      <div
        className=" d-flex align-items-center justify-content-around flex-column p-5"
        style={{
          background: "var(--events-default-backdrop-color)",
          width: "90%",
          borderRadius: "30px",
          height: "500px",
        }}
      >
        <div className="w-100 size-12 bold my-2 px-4 text-center">
          {assets?.events_my_locker_confirmation_title ||
            "Guarda tus pertenencias y cierra el casillero!"}
          <br />
          <br />
          {assets?.events_my_locker_confirmation_subtitle ||
            "Puedes guardar y sacar cosas las veces que quieras presionando en Mi casillero"}
        </div>
        {boxes &&
          boxes?.map((box: any) => (
            <CardOpenBoxResidential
              {...box}
              key={box.id}
              data={box}
              action={action}
              boxQuantity={boxes?.length}
            />
          ))}
      </div>
    </div>
  );
};

export default BoxToOpenPickupMyBox;
