/* eslint-disable react-hooks/exhaustive-deps */
import Header from "../../components/Header";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { set_loader } from "../../../../redux/actions/loader";
import HiddenQrRreader from "../../components/HiddenQrReader";
import Drawer from "../../../../components/Drawer";
import NumberKeyboard from "../../components/NumberKeyboard";
import { useNavigate } from "react-router-dom";
import { _pushToastMessage } from "../../../../helpers/messages";
import qrUnab from "../../../../assets/png/qrcode_unab.png";
import NumberList from "../../../../components/NumberList";

const Home = () => {
  const [deliveryDrawer, setDeliveryDrawer] = useState({
    open: false,
    data: {},
  });

  const navigate = useNavigate();

  const dispatch: any = useDispatch();
  useEffect(() => {
    const timeout: any = setTimeout(() => {
      dispatch(set_loader({ is_loading: false }));
      console.log("CLOSE LOADER");
    }, 5000);

    return () => {
      clearInterval(timeout);
      console.log("CLEAR INTERVAL");
    };
  }, []);

  const _handleOnConfirmKeyboard = (apartament: number) => {
    if (!apartament) {
      _pushToastMessage({
        type: "warning",
        text: "Ingrese un número de departamento / casa.",
        header: "Aviso",
      });
      return;
    }
    navigate(`/delivery-with-apartament/${apartament}`, { replace: true });
  };

  return (
    <div className="container-fluid h-100">
      <Header />
      <HiddenQrRreader />
      <Drawer
        isOpen={deliveryDrawer.open}
        onClose={() => setDeliveryDrawer({ open: false, data: {} })}
      >
        <NumberKeyboard
          isOpen={deliveryDrawer.open}
          onConfirm={_handleOnConfirmKeyboard}
        />
      </Drawer>

      <div className="content-section">
        <div className="row justify-content-center my-5">
          <div className="w-75 text-center mt-5">
            <h1 className="">
              <i>AUTOPRÉSTAMO DE NOTEBOOK</i>
            </h1>
          </div>
        </div>
          <div className="d-flex  justify-content-center mt-5 px-4">
            <div className="col-4 d-flex flex-column">
              <div className="mb-4" style={{ fontSize: "24px" }}>
                ¿Como <b>solicito</b>?
              </div>
              <div className="px-2 size-09">
                <NumberList
                  number={1}
                  title="Escanea el QR"
                  text="Ingresa usuario y contraseña UNAB"
                />
                <NumberList
                  number={2}
                  title="Selecciona tu Biblioteca de retiro"
                  text="Confirma (tienes un maximo de 15 minutos para retirar el equipo)"
                />
                <NumberList
                  number={3}
                  title="Acercate al Autopréstamo"
                  text="Escanea el QR de tu carnet de identidad para realizar el préstamo"
                />
                <NumberList
                  number={4}
                  title="Retira el notebook y cargador del casillero ;)"
                  text=""
                />
              </div>
            </div>
            <div className="col-4 d-flex flex-column justify-content-center align-items-center">
              <div className="bold mb-2 py-1 px-3 text-center" style={{background: "#000", color: "#FFF", borderRadius: '15px', width: '200px'}}>Escanea el codigo</div>
              <img
                src={qrUnab}
                alt="qrunab"
                className="p-0 m-0"
                style={{
                  width: "200px",
                  borderRadius: "10px",
                  border: "8px solid #AB1D2D",
                }}
              />
              <div className="mt-2">
                <span className="bold" style={{color: '#AB1D2D'}}>QR</span> de Autopréstamo
              </div>
            </div>
            <div className="col-4">
              <div className="mb-4" style={{ fontSize: "24px" }}>
                ¿Como <b>devuelvo</b>?
              </div>
              <div className="px-2 size-09">
                <NumberList
                  number={1}
                  title="Acércate al Autopréstamo"
                  text=""
                />
                <NumberList
                  number={2}
                  title="Escanea el código del notebook"
                  text="y el cargador que solicitaste"
                />
                <NumberList
                  number={3}
                  title="Automaticamente el Autopréstamo"
                  text="te asignará un casillero"
                />
                <NumberList
                  number={4}
                  title="Guarda el notebook"
                  text="y el cargador en el casillero asignado."
                />
                <NumberList
                  number={5}
                  title="¡Recuerda cerrar el casillero! ;)"
                  text=""
                />
              </div>
            </div>
          </div>
        </div>
    </div>
  );
};

export default Home;
