/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Keyboard from "../../../../components/Keyboard";
import Logo from "../../../../components/Logo";
import { _pushToastMessage } from "../../../../helpers/messages";
import { useSelector } from "react-redux";
import { io } from "socket.io-client";
import LendingService from "../../../../services/lending.service";
import { useDispatch } from "react-redux";
import { set_loader } from "../../../../redux/actions/loader";
import { set_boxes } from "../../../../redux/actions/boxes";
import HiddenQrRreader from "../../components/HiddenQrReader";

const InsertCode = () => {
  const [state, setState] = useState<any>({
    data: "",
  });

  const keyboardRef = useRef<any>();
  const navigate = useNavigate();
  const codes = useSelector((state: any) => state.codes);
  const [searchParams] = useSearchParams();

  const session = useSelector((state: any) => state.session);

  const Lending = new LendingService();

  const dispatch: any = useDispatch();

  const [redirectTarget, setRedirectTarget] = useState("/aaa");

  useEffect(() => {

    if (searchParams.has("item") && searchParams.get("item") === "delivery-article") {
      setRedirectTarget("/delivery-articles?action=back");
    }

    if (searchParams.has("item") && searchParams.get("item") === "load-article") {
      setRedirectTarget("/load-articles?action=back");
    }

    const socket: any = io("http://127.0.0.1:3333");
    socket.on("news", (data: any) => {
      console.log(data);
    });

    socket.on("code", (data: any) => {
      if (searchParams.has("item") && searchParams.get("item") !== "document") {
        return;
      }
      const code: any = data.replace(/[\r\n]/gm, "");

      const url: any = code.toLowerCase();

      const search = url.split("?")[1];

      const urlSearchParams = new URLSearchParams(search);

      if (urlSearchParams.has("run")) {
        const run = urlSearchParams.get("run");
        const finalCode = run?.replace(/[^a-zA-Z0-9]/g, "");
        _validateCode(finalCode);
        return;
      }

      _pushToastMessage({
        header: "Error",
        text: "Este código no es válido",
        type: "error",
      });
      setState({ data: "" });
    });

    socket.on("connect_error", () => console.log("CONECT ERROR"));
    socket.on("reconnect_error", () => console.log("RECONECT ERROR"));

    return () => {
      console.log("Destroy socket connection");
      socket.destroy();
    };
  }, []);

  const _validateCode = async (code: any) => {
    try {
      dispatch(set_loader({ is_loading: true }));
      const response = await Lending.getLendingByDocument({
        terminal_id: session.profile.id,
        code: code.toLowerCase(),
      });

      const pickupPayload = {
        reservation_id: response.data.id,
        code: response.data.code,
        terminal_id: response.data.terminal_id,
      };

      await Lending.pickup(pickupPayload);

      const boxes = response.data.boxes;

      dispatch(set_boxes({ box_list: boxes }));

      navigate("/pickup-article-success");

      dispatch(set_loader({ is_loading: false }));
    } catch (e: any) {
      dispatch(set_loader({ is_loading: false }));
      _pushToastMessage({
        text:
          e?.response?.data?.message ||
          e?.message ||
          "No fue encontrar una reserva con este codigo.",
        type: "error",
        header: "Error",
      });
    }

    setState({ data: code });
  };

  const _handleOnKeyPress = async (button: any) => {
    if (button === "{bksp}") {
      setState({ data: state.data.slice(0, -1) });
      return;
    }

    if (button === "{enter}") {
      if (!state.data) {
        _pushToastMessage({
          type: "warning",
          text: "Ingrese un codigo.",
          header: "Aviso",
        });
        return;
      }

      if (searchParams.has("item")) {
        const item = searchParams.get("item");

        switch (item) {
          case "document":
            await _validateCode(state.data);
            break;
          case "load-article":
            const thisCodeExists = codes?.codes.find(
              (c: any) => c.code.toUpperCase() === state.data.toUpperCase()
            );

            if (thisCodeExists) {
              _pushToastMessage({
                text: `El código ${state.data.toUpperCase()} ya fue ingresado`,
                type: "error",
                header: "Error",
              });
              break;
            }

            navigate(`/load-articles?add=${state.data}`, { replace: true });
            break;
          case "delivery-article":
            navigate(`/delivery-articles?add=${state.data}`, { replace: true });
            break;
          default:
            _pushToastMessage({
              type: "error",
              text: "Item no encontrado",
              header: "Error",
            });
            break;
        }

        return;
      }
    }

    setState({ data: state.data + button });
  };

  const _handleOnChange = (value: any) => {
    console.log("ONCHANGE", value);
  };

  return (
    <div className="container-fluid h-100">
      <div className="row box rounded-0 p-3" style={{ height: "35vh" }}>
        <div className="col-12 mt-3">
          <Logo style={{ width: "220px" }} company="unab" />
        </div>
        <div
          className="col-12 text-center bold size-14"
          style={{ textTransform: "uppercase" }}
        >
          {searchParams.has("item") &&
            searchParams.get("item") === "document" && (
              <HiddenQrRreader
                onChange={(code: any) => setState({ ...state, data: code })}
              />
            )}

          {searchParams.has("item") &&
            searchParams.get("item") === "document" && (
              <div>Escanea su Rut</div>
            )}

          {searchParams.has("item") &&
            searchParams.get("item") !== "document" && (
              <div>Ingresa el codigo del articulo</div>
            )}
        </div>
        <div
          className="col-12 d-flex justify-content-center align-items-end"
          style={{ height: "90px" }}
        >
          {state.data?.split("").map((item: any, index: any) => (
            <div
              className="bold px-2 mx-2 py-0 my-0"
              style={{ fontSize: "42px", borderBottom: "4px solid #000" }}
              key={index}
            >
              {item}
            </div>
          ))}
        </div>
      </div>

      <div
        className="row align-items-center px-4 keyboard"
        style={{ color: "#000", height: "65vh" }}
      >
        {searchParams.has("item") &&
          searchParams.get("item") !== "document" && (
            <Keyboard
              onKeyPress={_handleOnKeyPress}
              onChange={_handleOnChange}
              keyboardRef={keyboardRef}
              layoutType={"spanish_basic_with_enter"}
            />
          )}

        <div className="col-12 text-center mb-3">
          <button
            className="main-button border-0 px-5 py-3 ms-3 bold"
            onClick={() => navigate(redirectTarget)}
          >
            Volver
          </button>
        </div>
      </div>
    </div>
  );
};

export default InsertCode;
