/* eslint-disable react-hooks/exhaustive-deps */
import Header from "../../components/Header";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ConfirmationModal from "../../../../components/ConfirmationModal";
import { useSelector } from "react-redux";
import { _handleError } from "../../../../helpers/errors";
import { useDispatch } from "react-redux";
import { set_loader } from "../../../../redux/actions/loader";
import { clear_pickup } from "../../../../redux/actions/pickup";
import RegressiveCounter from "../../../../components/RegressiveCounter";
import BoxToOpenPickupMyBox from "../../components/BoxToOpenPickupMyBox";
import { handleAllColorStyles } from "../../../../helpers/dynamic_styles";

const MyBoxPickup = () => {
  const [state, setState] = useState({
    data: "",
    modal: { headerText: "", contentText: "", open: false, type: "warning" },
  });
  const navigate = useNavigate();
  const { pickup, session } = useSelector((state: any) => state);
  const { boxes } = pickup;
  const dispatch: any = useDispatch();
  const [paused, setPaused] = useState(true);

  const [intervalStateControl, setIntervalStateControl] = useState(0);

  const assets = session?.profile?.assets?.assets;

  useEffect(() => {
    handleAllColorStyles(assets);
    const closed: any = boxes.find((item: any) => !item.opened);
    if (!closed) setPaused(false);
  }, [pickup]);

  const _handleConfirmModal = async () => {
    setIntervalStateControl(intervalStateControl + 1);

    try {
      dispatch(set_loader({ is_loading: false }));
      dispatch(clear_pickup());
      navigate("/");
    } catch (e: any) {
      _handleError(
        e,
        "No se puede completar el retiro. Encuentra una persona responsable que te informe sobre tu problema."
      );
      dispatch(set_loader({ is_loading: false }));
    }
  };

  const _handleCancelModal = () => {
    setState({
      ...state,
      modal: {
        ...state.modal,
        open: false,
      },
    });
  };

  return (
    <div className="container-fluid h-100">
      <ConfirmationModal
        {...state.modal}
        onConfirm={_handleConfirmModal}
        onCancel={_handleCancelModal}
      />
      <Header />
      <div className="content-section px-4 d-flex flex-column justify-content-around">
        <BoxToOpenPickupMyBox action="pickup" />
        <div className="row mb-4">
          <div className="col-12 text-center px-0">
            <button
              className="px-4 main-inverted-dynamic-button bold"
              onClick={_handleConfirmModal}
            >
              <div className="d-flex justify-content-center align-items-center">
                <div className="me-3">Finalizar</div>
                <RegressiveCounter
                  clearIntervalAction={intervalStateControl}
                  handleCallback={_handleConfirmModal}
                  paused={paused}
                />
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyBoxPickup;
