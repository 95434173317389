/* eslint-disable react-hooks/exhaustive-deps */
import Header from "../../components/Header";
// import { ReactComponent as Reservation } from "../../../../assets/svg/locker.svg";
import Pinpad from "../../../../assets/png/Pinpad.png";
import ItemAction from "../../components/ItemAction";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { set_loader } from "../../../../redux/actions/loader";
import HiddenQrRreader from "../../components/HiddenQrReader";

const Home = () => {
  const navigate = useNavigate();
  const dispatch: any = useDispatch();
  useEffect(() => {
    const timeout: any = setTimeout(() => {
      dispatch(set_loader({ is_loading: false }));
      console.log("CLOSE LOADER");
    }, 5000);

    return () => {
      clearInterval(timeout);
      console.log("CLEAR INTERVAL");
    };
  }, []);

  return (
    <div className="container-fluid h-100">
      <Header />
      <HiddenQrRreader />
      <div className="content-section">
        <div className="row align-items-center justify-content-center my-5">
          <div
            className="col-8 text-center p-5"
            style={{
              fontSize: "32px",
              fontWeight: "bold",
              border: "2px solid #ffe700",
              borderRadius: "10px",
              textTransform: "uppercase",
            }}
          >
            Estimado cliente:
            <br />
            Escanea tu código QR para retirar tu producto
          </div>
        </div>
        <div className="row align-items-center justify-content-center mt-5">
          <ItemAction
            label=""
            Image={() => (
              <img
                src={Pinpad}
                alt="Pinpad"
                style={{ width: "70px", height: "70px" }}
              />
            )}
            description="O presiona aquí para digitar tu código de retiro"
            handleOnClick={() => navigate("/services", { replace: true })}
            disabled={false}
          />
        </div>
      </div>
    </div>
  );
};

export default Home;
