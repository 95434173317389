import { useEffect } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import SignIn from "../components/SignIn";
import Home from "../modules/DynamicLending/pages/Home";
import InsertCode from "../modules/DynamicLending/pages/InsertCode";
import LoadArticles from "../modules/DynamicLending/pages/LoadArticles";
import DeliveryArticle from "../modules/DynamicLending/pages/DeliveryArticle";
import DashboardRouter from "./DashboardRouter";
import SuccessLoadArticles from "../modules/DynamicLending/pages/SuccesLoadArticles";
import SuccessPickupArticles from "../modules/DynamicLending/pages/SuccesPickupArticles";
import Version from "../components/Version";
import FailedDeliveryArticles from "../modules/DynamicLending/pages/FailedDeliveryArticles";
import SuccessUserLoadArticles from "../modules/DynamicLending/pages/SuccessUserLoadArticles";

export default function DynamicLendingRouter() {
  const { session } = useSelector((state: any) => state);
  useEffect(() => {
    import("../modules/DynamicLending/styles/_global_lending.scss");
  }, []);
  return (
    <BrowserRouter>
      <SignIn open={!session.is_connected || session.sign_in_component} />
      <Version />
      <Routes>
        {/* Routes */}
        <Route path="/" element={<Home />} />
        <Route path="/load-articles" element={<LoadArticles />} />
        <Route path="/delivery-articles" element={<DeliveryArticle />} />
        <Route path="/insert-code" element={<InsertCode />} />
        <Route path="/load-article-success" element={<SuccessLoadArticles />} />
        <Route path="/load-article-user-success" element={<SuccessUserLoadArticles />} />
        <Route path="/load-article-failed" element={<FailedDeliveryArticles />} />
        <Route path="/pickup-article-success" element={<SuccessPickupArticles />} />

        <Route path="/dashboard/*" element={<DashboardRouter />} />
      </Routes>
    </BrowserRouter>
  );
}
