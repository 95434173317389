/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Keyboard from "../../../../components/Keyboard";
import Logo from "../../../../components/Logo";
import { _pushToastMessage } from "../../../../helpers/messages";
import EventService from "../../../../services/events.service";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { set_loader } from "../../../../redux/actions/loader";

const HelpUserKeyboard = () => {
  const [state, setState] = useState<any>({
    data: "",
    keyboardLayout: {
      label: "ABC",
      value: "spanish_only_numbers",
    },
  });

  const keyboardRef = useRef<any>();
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const dispatch: any = useDispatch();
  const { session } = useSelector((state: any) => state);
  const assets = session?.profile?.assets?.assets;
  useEffect(() => {
    if (urlParams.has("phone")) {
      setState({ ...state, data: urlParams.get("phone") });
    }
  }, []);

  const _handleOnKeyPress = async (button: any) => {
    if (button === "{bksp}") {
      setState({ ...state, data: state.data.slice(0, -1) });
      return;
    }

    if (button === "{enter}") {
      if (state.data.length < 9) {
        _pushToastMessage({
          type: "warning",
          text: "Ingrese un número de teléfono válido.",
          header: "Aviso",
        });
        return;
      }

      try {
        /**
         *  TODO: SEND MESSAGE TO ADMIN
         */
        dispatch(set_loader({ is_loading: true }));
        await new EventService().sendHelpUserMessage({
          phone: state.data,
          terminal_id: session.profile?.id,
        });
        dispatch(set_loader({ is_loading: false }));
        navigate(`/help-user/phone/success`, { replace: true });
      } catch (e) {
        dispatch(set_loader({ is_loading: false }));
        navigate(`/help-user/phone/failed`, { replace: true });
      }

      return;
    }

    if (state.data.length >= 9) return;

    setState({ ...state, data: state.data + button });
  };

  const _handleOnChange = (value: any) => {
    console.log("ONCHANGE", value);
  };

  return (
    <div className="container-fluid h-100">
      <div className="w-100 text-center my-4">
        <Logo
          url={assets?.events_default_logo_upload}
          style={{ maxWidth: "100px" }}
        />
      </div>
      <div className="d-flex justify-content-center align-items-center p-0">
        <div
          className=" d-flex align-items-center justify-content-around flex-column p-5"
          style={{
            background: "var(--events-default-backdrop-color)",
            width: "90%",
            borderRadius: "30px",
            height: "650px",
          }}
        >
          <div className="w-100 text-center bold size-14">
            {assets?.events_help_text ||
              "Ingresa tu número telefónico y te contactaremos enseguida!"}
          </div>

          <div>
            <div
              className="w-100 d-flex justify-content-center align-items-end my-4 pb-2"
              style={{
                color: "#000",
                background: "var(--events-pinpad-bg-numbers-color)",
                borderRadius: "10px",
                minWidth: "300px",
                minHeight: "80px",
              }}
            >
              {"(+56)".split("").map((item: any, index: any) => (
                <div
                  className="bold px-2 py-0 my-0"
                  style={{
                    fontSize: "38px",
                    borderBottom:
                      "4px solid var(--events-pinpad-text-numbers-color)",
                    color: "var(--events-pinpad-text-numbers-color)",
                  }}
                  key={index}
                >
                  {item}
                </div>
              ))}
              {state.data?.split("").map((item: any, index: any) => {
                if (index === 4) {
                  return (
                    <>
                      <div
                        className="bold px-2 mx-2 py-0 my-0"
                        style={{
                          fontSize: "38px",
                          borderBottom:
                            "4px solid var(--events-pinpad-text-numbers-color)",
                          color: "var(--events-pinpad-text-numbers-color)",
                        }}
                        key={index}
                      >
                        {item}
                      </div>
                      <div
                        className="bold px-2 mx-2 py-0 my-0"
                        style={{
                          fontSize: "38px",
                          borderBottom:
                            "4px solid var(--events-pinpad-text-numbers-color)",
                          color: "var(--events-pinpad-text-numbers-color)",
                        }}
                        key={"-"}
                      >
                        -
                      </div>
                    </>
                  );
                }

                return (
                  <div
                    className="bold px-2 mx-2 py-0 my-0"
                    style={{
                      fontSize: "38px",
                      borderBottom:
                        "4px solid var(--events-pinpad-text-numbers-color)",
                      color: "var(--events-pinpad-text-numbers-color)",
                    }}
                    key={index}
                  >
                    {item}
                  </div>
                );
              })}
            </div>
          </div>

          <div
            className="w-100 keyboard-number"
            style={{ color: "#000", height: "65vh" }}
          >
            <Keyboard
              onKeyPress={_handleOnKeyPress}
              onChange={_handleOnChange}
              keyboardRef={keyboardRef}
              layoutType={state.keyboardLayout?.value}
            />
          </div>

          <div className="text-center my-3">
            <button
              className="px-5 py-2 main-borderless-dynamic-button bold"
              onClick={() => navigate("/")}
            >
              Volver
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HelpUserKeyboard;
