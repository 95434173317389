import api from "./api_locker";
import store from "../redux/store";

class LocalTerminalService {
  constructor() {
    const { session } = store.getState();
    api.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${session.security_token}`;
  }

  createReservation = async (payload: any) => {
    try {
      const response = await api.post(`/api/v1/terminal/delivery`, payload);
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  createCustodyReservation = async (payload: any) => {
    try {
      const response = await api.post(`/api/v1/terminal/custody`, payload);
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };
}

export default LocalTerminalService;
