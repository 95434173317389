import { useSelector } from "react-redux";
import loaderGif from "../../assets/gifs/loader-transparent.gif";

const Loader = () => {
  const { loader } = useSelector((state: any) => state);

  return (
    <div
      className="loader_elock_dynamic"
      style={{
        width: "100%",
        height: "100vh",
        position: "fixed",
        top: 0,
        left: 0,
        backgroundColor: "#00000050",
        zIndex: 1051,
        display: loader.is_loading ? "flex" : "none",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img src={loaderGif} alt="loader" style={{ width: "50px" }} />
    </div>
  );
};

export default Loader;
