import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Clock from "../../../../components/Clock";
import Logo from "../../../../components/Logo";
import { set_session } from "../../../../redux/actions/session";
import "./component.header.scss";

const Header = () => {
  const { session } = useSelector((state: any) => state);
  const dispatch: any = useDispatch();
  const assets = session?.profile?.assets?.assets;
  const _handleSigIn = () => {
    dispatch(set_session({ ...session, sign_in_component: true }));
  };
  return (
    <div className="row component-header mx-5">
      <div className="col-8">
        <div style={{ width: "100px" }}>
          <button className="btn p-0 m-0" onClick={_handleSigIn}>
            <Logo
              url={assets?.events_default_logo_upload}
              style={{ maxWidth: "100px" }}
            />
          </button>
        </div>
      </div>
      <div className="col-4 text-end">
        <Clock />
      </div>
    </div>
  );
};

export default Header;
