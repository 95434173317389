/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Keyboard from "../../../../components/Keyboard";
import Logo from "../../../../components/Logo";
import { _pushToastMessage } from "../../../../helpers/messages";
import { useSelector } from "react-redux";

const ApartmentKeyboard = () => {
  const [state, setState] = useState<any>({
    data: "",
    keyboardLayout: {
      label: "ABC",
      value: "spanish_only_numbers",
    },
  });

  const keyboardRef = useRef<any>();
  const navigate = useNavigate();

  const { profile } = useSelector((state: any) => state.session);
  const assets = profile?.assets?.assets;

  const _handleOnKeyPress = (button: any) => {
    console.log("[state]", state.data, "[button]", button);

    if (button === "{bksp}") {
      setState({ ...state, data: state.data.slice(0, -1) });
      return;
    }

    if (button === "{enter}") {
      if (!state.data) {
        _pushToastMessage({
          type: "warning",
          text: "Ingrese un número de departamento / casa.",
          header: "Aviso",
        });
        return;
      }
      navigate(`/delivery-with-apartament/${state.data}`, { replace: true });
      return;
    }

    setState({ ...state, data: state.data + button });
  };

  const _handleOnChange = (value: any) => {
    console.log("ONCHANGE", value);
  };

  const _toggleKeyboard = () => {
    setState({
      ...state,
      keyboardLayout: {
        label: state.keyboardLayout?.label === "ABC" ? "123" : "ABC",
        value:
          state.keyboardLayout?.value === "spanish_only_numbers"
            ? "spanish_basic_without_space_with_ok"
            : "spanish_only_numbers",
      },
    });
  };

  return (
    <div className="container-fluid h-100">
      <div className="w-100 text-center my-4">
        <Logo style={{ width: "100px" }} />
      </div>
      <div className="d-flex justify-content-center align-items-center p-0">
        <div
          className=" d-flex align-items-center justify-content-around flex-column p-5"
          style={{
            background: "rgba(255, 255, 255, 0.1)",
            width: "90%",
            borderRadius: "30px",
            height: "650px",
          }}
        >
          <div className="w-100 text-center bold size-14">
            {assets?.delivery_step_one_text_one || "Digita el número de Departamento/Casa"}
          </div>

          <div>
            <div
              className="w-100 d-flex justify-content-center my-4 pb-2"
              style={{
                color: "#000",
                background: "rgba(255, 255, 255, 0.1)",
                borderRadius: "10px",
                minWidth: "300px",
                minHeight: "80px",
              }}
            >
              {state.data?.split("").map((item: any, index: any) => (
                <div
                  className="bold px-2 mx-2 py-0 my-0"
                  style={{
                    fontSize: "48px",
                    borderBottom: "4px solid #FFF",
                    color: "#FFF",
                  }}
                  key={index}
                >
                  {item}
                </div>
              ))}
            </div>
          </div>

          <div
            className="w-100 keyboard-number"
            style={{ color: "#000", height: "65vh" }}
          >
            <Keyboard
              onKeyPress={_handleOnKeyPress}
              onChange={_handleOnChange}
              keyboardRef={keyboardRef}
              layoutType={state.keyboardLayout?.value}
            />
            {assets &&
              assets?.delivery_step_one_keyboard_type ===
                "spanish_basic_without_space_with_ok" && (
                <div className="p-0 mt-2 d-flex justify-content-center w-100">
                  <button
                    className="hg-button rounded py-3"
                    style={{ width: "250px", marginTop: "-10px" }}
                    onClick={_toggleKeyboard}
                  >
                    {state.keyboardLayout?.label}
                  </button>
                </div>
              )}
          </div>

          <div className="text-center my-3">
            <button
              className="main-button px-5 py-2 ms-3 bold"
              onClick={() => navigate("/")}
            >
              Volver
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApartmentKeyboard;
