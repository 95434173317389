/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Button } from "rsuite";
import MasterKeyAuth from "../../../components/MasterKeyAuth";
import { _handleError } from "../../../helpers/errors";
import { _pushToastMessage } from "../../../helpers/messages";
import { set_loader } from "../../../redux/actions/loader";
import { loader } from "../../../redux/reducers";
import DashboardTemplate from "../DashboardTemplate";
import SyncService from "../../../services/syn.service";
import { useSelector } from "react-redux";

const DashboardOffline = () => {
  const [masterKeyModal, setMasterKeyModal] = useState(false);
  const { session } = useSelector((state: any) => state);
  const dispatch: any = useDispatch();

  const _handleSync = async (payload: any) => {
    console.log("Sync terminal", payload);
    try {
      dispatch(set_loader({ ...loader, is_loading: true }));
      const Sync = new SyncService();
      await Sync.syncData({ terminal_id: session?.profile?.id });
      dispatch(set_loader({ ...loader, is_loading: false }));
      setMasterKeyModal(false);
      _pushToastMessage({
        header: "Sincronización",
        text: "Sincronización completada",
        type: "success",
      });
    } catch (error) {
      dispatch(set_loader({ ...loader, is_loading: false }));
      _handleError(error, "Error al sincronizar la base de datos");
    }
  };

  const _syncRoutine = async () => {
    try {
      dispatch(set_loader({ is_loading: true }));

      const Sync = new SyncService();

      await Sync.syncDeliveries();
      await Sync.syncPickups();
      await Sync.syncCancels();

      _pushToastMessage({
        header: "Sincronización",
        text: "Sincronización completada",
        type: "success",
      });

      dispatch(set_loader({ is_loading: false }));
    } catch (e: any) {
      dispatch(set_loader({ is_loading: false }));
      _pushToastMessage({
        header: "Error",
        text: e.message,
        type: "error",
      });
    }
  };

  return (
    <DashboardTemplate>
      <MasterKeyAuth
        open={masterKeyModal}
        title="Informe su master key"
        handleClose={() => setMasterKeyModal(false)}
        handleConfirm={(payload: any) => _handleSync(payload)}
        description="El usuario {USER} sincronizó el terminal {TERMINAL} al dia {DATETIME} con la key {MASTERKEY}"
        action="SYNC_TERMINAL"
      />

      <div className="row px-4 py-2 mx-0 mb-4">
        <div
          className="col-12 my-2 pb-2 size-12"
          style={{ borderBottom: "2px solid #afafaf", fontWeight: "bold" }}
        >
          Modulo Offline
        </div>
      </div>

      <div className="row background-color-white shadow-sm px-3 py-5 mx-3 mt-3 rounded">
        <div className="col-12 px-5 size-09 mb-1" style={{ fontWeight: "500" }}>
          Syncronizar datos del terminal
        </div>
        <div className="col-12 text-end px-5 mt-1">
          <Button onClick={() => setMasterKeyModal(true)} appearance="primary">
            Sincronizar
          </Button>
        </div>
      </div>
      <div className="row background-color-white shadow-sm px-3 py-5 mx-3 mt-3 rounded">
        <div className="col-12 px-5 size-09 mb-1" style={{ fontWeight: "500" }}>
          Syncronizar Reservas
        </div>
        <div className="col-12 text-end px-5 mt-1">
          <Button onClick={_syncRoutine} appearance="primary">
            Sincronizar
          </Button>
        </div>
      </div>
    </DashboardTemplate>
  );
};

export default DashboardOffline;
