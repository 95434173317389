import api from "./api_locker";
import store from "../redux/store";

class LocalReservationService {
  constructor() {
    const { session } = store.getState();
    api.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${session.security_token}`;
  }

  checkCode = async (payload: any) => {
    try {
      const response = await api.post(`/api/v1/terminal/check-code`, payload);
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  finishOrder = async (payload: any) => {
    try {
      const response = await api.post(`/api/v1/terminal/pickup`, payload);
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  getReservationsToPick = async () => {
    try {
      const response = await api.get(`/api/v1/reservations/to-pick`);
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  cancelReservation = async (payload: any) => {
    try {
      const response = await api.post(`/api/v1/reservations/cancel`, payload);
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  }
}

export default LocalReservationService;
