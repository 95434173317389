export const TableHeader = [
  {
    label: "ID",
    key: "id",
    align: "center",
    alignFlex: "center",
    flexGrow: 1,
  },
  {
    label: "Caja",
    key: "box_number",
    align: "left",
    alignFlex: "flex-start",
    flexGrow: 1,
  },
  {
    label: "Articulos",
    key: "items",
    align: "center",
    alignFlex: "center",
    flexGrow: 1,
  },
];
