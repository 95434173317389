import api from "./api_locker";
import store from "../redux/store";

class SyncService {
  constructor() {
    const { session } = store.getState();
    api.defaults.headers.common["Authorization"] = `${session.security_token}`;
  }

  setHeaders = () => {
    const { session } = store.getState();
    api.defaults.headers.common["Authorization"] = `${session.security_token}`;
  };

  syncData = async ({ terminal_id }: any) => {
    try {
      const response = await api.get(`/api/v1/sync/aws/${terminal_id}`);
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  syncDeliveries = async () => {
    try {
      this.setHeaders();
      const response = await api.get(`/api/v1/sync/deliveries`);
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  syncPickups = async () => {
    try {
      this.setHeaders();
      const response = await api.get(`/api/v1/sync/pickups`);
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  syncCancels = async () => {
    try {
      this.setHeaders();
      const response = await api.get(`/api/v1/sync/cancels`);
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  syncDeliveryUsers = async () => {
    try {
      this.setHeaders();
      const response = await api.get(`/api/v1/sync/delivery-users`);
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  syncBoxes = async ({ terminal_id }: any) => {
    try {
      const response = await api.get(`/api/v1/sync/boxes/${terminal_id}`);
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };
}

export default SyncService;
