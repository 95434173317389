/* eslint-disable react-hooks/exhaustive-deps */
import Header from "../../components/Header";
import { ReactComponent as Delivery } from "../../../../assets/svg/new_delivery_icon.svg";
import { ReactComponent as Pickup } from "../../../../assets/svg/new_pickup_icon.svg";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { set_loader } from "../../../../redux/actions/loader";
import HiddenQrRreader from "../../components/HiddenQrReader";
import Drawer from "../../../../components/Drawer";
import NumberKeyboard from "../../components/NumberKeyboard";
import { useNavigate } from "react-router-dom";
import { _pushToastMessage } from "../../../../helpers/messages";
import HomeActionButton from "../../components/HomeActionButton";
import { useSelector } from "react-redux";

const Home = () => {
  const [deliveryDrawer, setDeliveryDrawer] = useState({
    open: false,
    data: {},
  });
  const { profile } = useSelector((state: any) => state.session);
  const assets = profile?.assets?.assets;
  const navigate = useNavigate();
  console.log(assets);
  const dispatch: any = useDispatch();
  useEffect(() => {
    const timeout: any = setTimeout(() => {
      dispatch(set_loader({ is_loading: false }));
      console.log("CLOSE LOADER");
    }, 5000);

    return () => {
      clearInterval(timeout);
      console.log("CLEAR INTERVAL");
    };
  }, []);

  const _handleOnConfirmKeyboard = (apartament: number) => {
    if (!apartament) {
      _pushToastMessage({
        type: "warning",
        text: "Ingrese un número de departamento / casa.",
        header: "Aviso",
      });
      return;
    }
    navigate(`/delivery-with-apartament/${apartament}`, { replace: true });
  };

  return (
    <div className="container-fluid h-100">
      <Header />
      <HiddenQrRreader />
      <Drawer
        isOpen={deliveryDrawer.open}
        onClose={() => setDeliveryDrawer({ open: false, data: {} })}
      >
        <NumberKeyboard
          isOpen={deliveryDrawer.open}
          onConfirm={_handleOnConfirmKeyboard}
        />
      </Drawer>

      <div className="content-section">
        <div
          className="row align-items-center justify-content-center mt-5"
          style={{ height: "500px" }}
        >
          <div
            className="h-100 d-flex align-items-center justify-content-center flex-column"
            style={{
              background: "rgba(255, 255, 255, 0.1)",
              width: "80%",
              borderRadius: "30px",
            }}
          >
            <div className="bold size-15 mb-5">
              {assets?.home_text_one ||
                "Selecciona si quieres entrega, retiro o custodia"}
            </div>
            <div className="d-flex align-items-center justify-content-center">
              {assets?.home_actions_one &&
                assets?.home_actions_one.includes(1) && (
                  <HomeActionButton
                    Image={Delivery}
                    title={assets?.home_btn_delivery_title || "Entrega"}
                    description={
                      assets?.home_btn_delivery_description ||
                      "Presiona aquí para entregar un paquete."
                    }
                    onClick={() =>
                      navigate("/info-apartment", { replace: true })
                    }
                  />
                )}
              <HomeActionButton
                Image={Pickup}
                title={assets?.home_btn_pickup_title || "Retiro"}
                description={
                  assets?.home_btn_pickup_description ||
                  "Presiona aquí para retirar un paquete."
                }
                onClick={() => navigate("/pickup", { replace: true })}
              />
              {assets?.home_actions_one &&
                assets?.home_actions_one.includes(2) && (
                  <HomeActionButton
                    Image={Delivery}
                    title={assets?.home_btn_custody_title || "Custodia"}
                    description={
                      assets?.home_btn_custody_description ||
                      "¡Deja un paquete para que retire quien quieras!"
                    }
                    onClick={() => navigate("/info-custody", { replace: true })}
                  />
                )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
