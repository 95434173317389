/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate, useSearchParams } from "react-router-dom";
import Header from "../../components/Header";
import { useEffect, useState } from "react";
import { io } from "socket.io-client";
import { useDispatch } from "react-redux";
import { set_loader } from "../../../../redux/actions/loader";
import { _pushToastMessage } from "../../../../helpers/messages";
import { clear_codes, set_codes } from "../../../../redux/actions/codes";
import { useSelector } from "react-redux";
import LendingService from "../../../../services/lending.service";
import { set_boxes } from "../../../../redux/actions/boxes";
import HiddenQrRreaderFree from "../../components/HiddenQrReaderFree";
const { v4: uuidv4 } = require("uuid");

const LoadArticles = () => {
  const [codes, setCodes] = useState<{ id: string; code: string }[]>([]);

  const navigate = useNavigate();
  const dispatch: any = useDispatch();
  const [searchParams] = useSearchParams();
  const { codes: reduxCodes, session } = useSelector((state: any) => state);
  const Lending = new LendingService();
  const assets = session?.profile?.assets?.assets;

  useEffect(() => {
    if (!searchParams.has("add") && !searchParams.has("action")) {
      dispatch(clear_codes());
      return;
    }

    if (searchParams.has("add")) {
      setCodes([
        ...reduxCodes.codes,
        { id: uuidv4(), code: searchParams.get("add") },
      ]);
      dispatch(
        set_codes({
          codes: [
            ...reduxCodes.codes,
            { id: uuidv4(), code: searchParams.get("add") },
          ],
        })
      );
      return;
    }

    setCodes([
      ...reduxCodes.codes,
    ]);
  }, []);

  useEffect(() => {
    const socket: any = io("http://127.0.0.1:3333");

    socket.on("news", (data: any) => {
      console.log(data);
    });

    socket.on("code", (data: any) => {
      const code: any = data.replace(/[\r\n]/gm, "");
      const finalCode = code.replace(/[^a-zA-Z0-9]/g, "");

      setCodes((prev) => {
        const thisCodeExists = prev.find(
          (c) => c.code.toUpperCase() === finalCode.toUpperCase()
        );

        if (thisCodeExists) {
          _pushToastMessage({
            text: `El código ${finalCode.toUpperCase()} ya fue ingresado`,
            type: "error",
            header: "Error",
          });
          return [...prev];
        }

        prev.push({ id: uuidv4(), code: finalCode.toUpperCase() });

        dispatch(set_codes({ codes: prev }));

        return [...prev];
      });
    });

    socket.on("connect_error", () => console.log("CONECT ERROR"));
    socket.on("reconnect_error", () => console.log("RECONECT ERROR"));

    dispatch(set_loader({ is_loading: false }));

    return () => {
      console.log("Destroy socket connection");
      socket.destroy();
    };
  }, []);

  const _handleRemoveCode = (id: string) => {
    const newCodes = codes.filter((c) => c.id !== id);
    setCodes(() => newCodes);
    dispatch(set_codes({ codes: newCodes }));
  };

  const handleSaveArticles = async () => {
    if (codes.length === 0) {
      _pushToastMessage({
        text: "No hay artículos para guardar",
        type: "error",
        header: "Error",
      });
      return;
    }

    const attributes: any = {};
    const items = codes.map((code) => {
      return {
        code: code.code,
      };
    });
    attributes.items = items;

    try {

      dispatch(set_loader({ is_loading: true }));
      const response: any = await Lending.saveArticles({
        attributes,
        terminal_id: session.profile.id,
      });
      
      const boxes = response.data?.reservation?.boxes;

      dispatch(set_boxes({ box_list: boxes }));
      dispatch(set_loader({ is_loading: false }));
      navigate("/load-article-success");

    } catch (e: any) {
      dispatch(set_loader({ is_loading: false }));
      _pushToastMessage({
        text:
          e?.response?.data?.message ||
          e?.message ||
          "No fue posible guardar los artículos",
        type: "error",
        header: "Error",
      });
    }
  };

  const _handleOnChangeQrReaderFree = (data: any) => {
    const code: any = data.replace(/[\r\n]/gm, "");
    const finalCode = code.replace(/[^a-zA-Z0-9]/g, "");

    if (finalCode.length === 0) return;

    setCodes((prev) => {
      const thisCodeExists = prev.find(
        (c) => c.code.toUpperCase() === finalCode.toUpperCase()
      );

      if (thisCodeExists) {
        _pushToastMessage({
          text: `El código ${finalCode.toUpperCase()} ya fue ingresado`,
          type: "error",
          header: "Error",
        });
        return [...prev];
      }

      prev.push({ id: uuidv4(), code: finalCode.toUpperCase() });

      dispatch(set_codes({ codes: prev }));

      return [...prev];
    });
  };

  return (
    <div className="container-fluid h-100">
      <Header />
      <HiddenQrRreaderFree onChange={_handleOnChangeQrReaderFree} />
      
      <div className="row">
        <div
          className="col-12 px-5 mb-4"
          style={{ height: "550px", overflow: "auto" }}
        >
          <div className="w-100 text-center bold size-14 uppercase">
            {assets?.lending_load_step_one_text_one || "ESCANEA O INGRESA EL CODIGO DE LOS ARTICULOS"}
          </div>

          <div className="w-100 d-flex mt-5" style={{ flexWrap: "wrap" }}>
            <div
              style={{ borderBottom: "1px solid #FFF" }}
              className="w-50 text-center bold text-12"
            >
              CÓDIGO
            </div>
            <div
              style={{ borderBottom: "1px solid #FFF" }}
              className="w-50 text-center bold text-12"
            >
              ACCIÓN
            </div>
            {codes.map((code) => (
              <div
                className="w-100 d-flex align-items-center"
                style={{ borderBottom: "1px solid #FFF" }}
                key={code.id}
              >
                <div className="w-50 text-center bold text-11 my-3">
                  {code.code}
                </div>
                <div className="w-50 text-center bold text-11 my-3">
                  <button
                    className="btn color-white border radius"
                    onClick={() => _handleRemoveCode(code.id)}
                  >
                    Eliminar
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="col-12 text-center mb-3">
          <button
            className="main-button border-0 px-5 py-3 ms-3 bold"
            onClick={() => navigate("/")}
          >
            Volver
          </button>
          <button
            className="main-button border-0 px-5 py-3 ms-3 bold"
            onClick={() => navigate("/insert-code?item=load-article")}
          >
            Ingresar código manual
          </button>
          <button
            className="main-button border-0 px-5 py-3 ms-3 bold"
            onClick={handleSaveArticles}
          >
            Confirmar
          </button>
        </div>
      </div>
    </div>
  );
};

export default LoadArticles;
