/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Table, Tooltip, Whisper } from "rsuite";
import DashboardTemplate from "../DashboardTemplate";
import { TableHeader } from "./table";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { _handleError } from "../../../helpers/errors";
import { set_loader } from "../../../redux/actions/loader";
import LendingService from "../../../services/lending.service";
import { TiTrash } from "react-icons/ti";
import { _pushToastMessage } from "../../../helpers/messages";
const DashboardLending = () => {
  const { session } = useSelector((state: any) => state);
  const dispatch: any = useDispatch();
  const Lending = new LendingService();
  const [state, setState] = useState<any>({
    data: [],
    selectedOrder: {},
  });

  useEffect(() => {
    _getLending();
  }, []);

  const _getLending = async () => {
    try {
      dispatch(set_loader({ is_loading: true }));
      const response = await Lending.getAvailableArticlesTerminal({
        terminal_id: session?.profile?.id,
        is_accessible: 2,
      });

      const data = response?.data?.reservations?.map((item: any) => {
        const items = item.boxes.map((box: any) => {
          return box.packages?.map((packageItem: any) => {
            return packageItem.attributes.items;
          });
        });

        item.items = items[0][0];

        item.items = item.items.map((item: any) => item.code);
        return item;
      });

      setState({ ...state, data: data });
      dispatch(set_loader({ is_loading: false }));
    } catch (e: any) {
      dispatch(set_loader({ is_loading: false }));
      _handleError(e, e.message);
    }
  };

  const deleteLending = async (payload: any) => {
    console.log(payload);
    try {
      dispatch(set_loader({ is_loading: true }));
      await Lending.deleteLending({
        terminal_id: session?.profile?.id,
        reservation_id: payload.id,
      });
      _pushToastMessage({
        header: "Artículo eliminado",
        type: "success",
        text: "El artículo ha sido eliminado correctamente.",
      });
      _getLending();
    } catch (e: any) {
      dispatch(set_loader({ is_loading: false }));
      _handleError(e, e.message);
    }
  };

  const Actions = (props: any) => {
    const { payload } = props;
    return (
      <div className="row me-3">
        <div className="col-12">
          <button
            className="btn px-0"
            onClick={() => deleteLending({ id: payload.id })}
          >
            <TiTrash style={{ fontSize: "1.5rem" }} />
          </button>
        </div>
      </div>
    );
  };

  return (
    <DashboardTemplate>
      <div className="w-100 row px-4 py-2 mx-0 mb-4 justify-content-around">
        <div
          className="col-12 my-2 pb-2 size-12"
          style={{ borderBottom: "2px solid #afafaf", fontWeight: "bold" }}
        >
          Artículos en el terminal
        </div>
      </div>

      <div className="row background-color-white shadow-sm px-3 py-5 mx-3 mt-3 rounded">
        <div className="col-12 px-1 ">
          <Table
            data={state.data}
            rowClassName="striped"
            autoHeight
            rowHeight={80}
            locale={{ emptyMessage: "No hay pedidos en el locker" }}
          >
            {TableHeader &&
              TableHeader.map((column: any, index: any) => (
                <Table.Column
                  align={column.align}
                  flexGrow={column.flexGrow}
                  key={`table-column-${index}`}
                >
                  <Table.HeaderCell>
                    <span
                      className="bold"
                      style={{ textTransform: "capitalize" }}
                    >
                      {column.label}
                    </span>
                  </Table.HeaderCell>
                  <Table.Cell
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: column.alignFlex,
                    }}
                  >
                    {(rowData) => {
                      switch (column.key) {
                        case "id":
                          return (
                            <Whisper
                              trigger="hover"
                              placement="auto"
                              controlId={`control-id-auto`}
                              speaker={<Tooltip>{rowData?.id}</Tooltip>}
                            >
                              <div
                                style={{
                                  width: "100%",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {rowData?.id}
                              </div>
                            </Whisper>
                          );
                        case "box_number":
                          return (
                            <Whisper
                              trigger="hover"
                              placement="auto"
                              controlId={`control-id-auto`}
                              speaker={
                                <Tooltip>
                                  {rowData?.boxes[0].door_number}
                                </Tooltip>
                              }
                            >
                              <div
                                style={{
                                  width: "100%",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {rowData?.boxes[0].door_number}
                              </div>
                            </Whisper>
                          );
                        case "items":
                          return (
                            <Whisper
                              trigger="hover"
                              placement="auto"
                              controlId={`control-id-auto`}
                              speaker={
                                <Tooltip>{rowData?.items?.join(" , ")}</Tooltip>
                              }
                            >
                              <div
                                style={{
                                  width: "100%",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {rowData?.items?.join(" , ")}
                              </div>
                            </Whisper>
                          );
                        default:
                          return (
                            <Whisper
                              trigger="hover"
                              placement="auto"
                              controlId={`control-id-auto`}
                              speaker={<Tooltip>{rowData[column.key]}</Tooltip>}
                            >
                              <div
                                style={{
                                  width: "100%",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {rowData[column.key]}
                              </div>
                            </Whisper>
                          );
                      }
                    }}
                  </Table.Cell>
                </Table.Column>
              ))}

            <Table.Column align="right" flexGrow={1} verticalAlign="middle">
              <Table.HeaderCell>{""}</Table.HeaderCell>
              <Table.Cell>
                {(rowData) => {
                  return <Actions payload={rowData} />;
                }}
              </Table.Cell>
            </Table.Column>
          </Table>
        </div>
      </div>
    </DashboardTemplate>
  );
};

export default DashboardLending;
