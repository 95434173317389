/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import RegressiveCounter from "../../../../components/RegressiveCounter";
import Header from "../../components/Header";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { set_loader } from "../../../../redux/actions/loader";
import { useNavigate } from "react-router-dom";
import EventService from "../../../../services/events.service";
import { handleAllColorStyles } from "../../../../helpers/dynamic_styles";
import BoxService from "../../../../services/box.service";
import DoorLockerService from "../../../../services/door_locker.service";
import { set_order } from "../../../../redux/actions/order";
import OrderService from "../../../../services/order.service";

const ConfirmationAndFinish = () => {
  const { order, session, loader } = useSelector((state: any) => state);
  const dispatch: any = useDispatch();
  const navigate = useNavigate();
  const [state, setState] = useState({
    message: "",
    loadingMessage: "",
  });
  const [paused, setPaused] = useState(true);

  const assets = session?.profile?.assets?.assets;

  useEffect(() => {
    handleAllColorStyles(assets);
    setState({
      message: "",
      loadingMessage: assets?.events_delivery_loading_text
        ? assets?.events_delivery_loading_text
        : "Por favor espera mientras organizamos todo. ;)",
    });
  }, [assets]);

  useEffect(() => {
    _getAvailableBoxesAndOpen();
  }, []);

  const _getAvailableBoxesAndOpen = async () => {
    /**
     * TODO:
     * Get all available boxes
     * if exists available boxes:
     * select first available box
     * open box
     * show message success or fail
     * if not exists available boxes:
     * show message
     */

    try {
      dispatch(set_loader({ is_loading: true }));

      const responseAvailableBoxes: any =
        await new BoxService().available_boxes(session?.profile?.id);

      const { data } = responseAvailableBoxes;
      const { user } = order;

      let box_list = data;

      box_list.sort((a: any, b: any) => {
        return a.is_accessible ? 1 : -1;
      });

      if (user.has_disability) {
        box_list = box_list.filter((box: any) => box.is_accessible);
      }

      if (box_list.length === 0) {
        throw new Error(
          assets?.events_delivery_single_fail_step_text
            ? assets?.events_delivery_single_fail_step_text
            : "No hay cajas disponibles"
        );
      }

      box_list = box_list.map((target: any) => {
        const box: any = {
          id: target?.box_type_id,
          name: target?.box_type?.name,
          quantity: box_list?.length,
          width: target?.box_type?.width,
          height: target?.box_type?.height,
          depht: target?.box_type?.depht,
          box_type_id: target?.box_type?.id,
          quantity_selected: 1,
        };

        return box;
      });

      const box = box_list[0];

      const response: any = await new EventService().init({
        user: order.user,
        boxes: [box],
        terminal: session?.profile,
      });

      const boxes = response.data?.boxes;

      if (boxes && boxes?.length === 0) {
        await new OrderService().cancelOrder({ id: response?.data?.order?.id });
        throw new Error(
          assets?.events_delivery_single_fail_step_text
            ? assets?.events_delivery_single_fail_step_text
            : "No hay cajas disponibles"
        );
      }

      const boxData = boxes[0];

      const payload: any = {
        com: session?.profile?.door_com_number,
        driveboard: boxData.desk_number,
        door: boxData.desk_door_number,
        box: boxData,
      };

      const finishOrder = {
        ...order,
        order: response.data?.order,
        boxes: response.data?.boxes,
      };

      const responseOpenDoor = await new DoorLockerService().openDoor(payload);

      if (responseOpenDoor.data?.status === "error") {
        await new OrderService().cancelOrder({ id: finishOrder?.order?.id });
        throw new Error("No fue posible abrir la puerta.");
      }

      dispatch(
        set_order({ order: response.data?.order, boxes: response.data?.boxes })
      );

      await new EventService().confirmOrder(finishOrder);

      setState({
        ...state,
        message: assets?.events_delivery_single_success_step_text
          ? assets?.events_delivery_single_success_step_text
          : "Guarda tus pertenencias y cierra la puerta.",
      });
      setPaused(false);

      dispatch(set_loader({ is_loading: false }));
    } catch (e: any) {
      setState({ ...state, message: e.message });
      dispatch(set_loader({ is_loading: false }));
      setPaused(false);
    }
  };

  const _handleConfirmOrder = async () => {
    try {
      navigate("/");
    } catch (e: any) {
      setState({ ...state, message: e.message });
      dispatch(set_loader({ is_loading: false }));
    }
  };

  return (
    <div className="container-fluid h-100">
      <Header />

      <div className="d-flex justify-content-center align-items-center mt-5">
        <div
          className=" d-flex align-items-center justify-content-around flex-column p-5"
          style={{
            background: "var(--events-default-backdrop-color)",
            width: "90%",
            borderRadius: "30px",
            height: "550px",
          }}
        >
          <div
            className="d-flex justify-content-center align-items-center text-center bold"
            style={{ fontSize: "32px" }}
          >
            {loader.is_loading ? state.loadingMessage : state.message}
          </div>

          <div className="w-100">
            <div className="w-100 text-center mt-4">
              <button
                className="px-4 main-inverted-dynamic-button bold"
                onClick={_handleConfirmOrder}
              >
                <div className="d-flex justify-content-center align-items-center">
                  <div className="me-3">Finalizar</div>
                  <RegressiveCounter
                    clearIntervalAction={0}
                    handleCallback={_handleConfirmOrder}
                    paused={paused}
                  />
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationAndFinish;
