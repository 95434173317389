/* eslint-disable react-hooks/exhaustive-deps */
import { useSelector } from "react-redux";
import "./styles.scss";
import { useEffect, useState } from "react";
import { IoClose, IoMap, IoVideocam } from "react-icons/io5";
import Logo from "../../../../components/Logo";

interface MultimediaProps {
  open: boolean;
  onClose: () => void;
  priority?: number;
  mediaData?: any;
}
const Multimedia = ({
  open,
  onClose,
  priority = 0,
  mediaData,
}: MultimediaProps) => {
  const { session } = useSelector((state: any) => state);

  const assets = session?.profile?.assets?.assets;

  const [state, setState] = useState({
    event_video: "",
    event_map: "",
    terminal_video: "",
    terminal_map: "",
  });

  const [activeMedia, setActiveMedia] = useState(0);

  useEffect(() => {
    _handleMediaData();
  }, [open]);

  const _handleMediaData = () => {
    const data = {
      event_video: mediaData?.events_company_map_video_url,
      event_map: mediaData?.events_company_map_img_upload,
      terminal_video: mediaData?.events_default_company_map_video_url,
      terminal_map: mediaData?.events_default_company_map_img_upload,
    };
    setState({ ...data });

    /**Event Priority */
    if (priority === 0) {
      if (data.event_map) {
        setActiveMedia(2);
        return;
      }

      if (data.event_video) {
        setActiveMedia(1);
        return;
      }

      if (data.terminal_map) {
        setActiveMedia(4);
        return;
      }

      if (data.terminal_video) {
        setActiveMedia(3);
        return;
      }
    }

    /**Terminal Priority */
    if (priority === 1) {
      if (data.terminal_map) {
        setActiveMedia(4);
        return;
      }

      if (data.terminal_video) {
        setActiveMedia(3);
        return;
      }

      if (data.event_map) {
        setActiveMedia(2);
        return;
      }

      if (data.event_video) {
        setActiveMedia(1);
        return;
      }
    }
  };

  const _handleSelectMedia = (media: number) => {
    console.log(media);
    setActiveMedia(media);
  };

  if (!open) return null;

  return (
    <div className="multimedia-component-modal" style={{ zIndex: 9999 }}>
      {activeMedia > 0 && (
        <div
          className="d-flex justify-content-center align-items-center bold size-15"
          style={{
            width: "100%",
            height: "100%",
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: -1,
          }}
        >
          Cargando...
        </div>
      )}
      {state.event_video && activeMedia === 1 && (
        <iframe
          width="100%"
          height="100%"
          src={`https://www.youtube.com/embed/${state.event_video}?controls=0&showinfo=0&autoplay=1&loop=1&mute=1&rel=0&playlist=${state.event_video}`}
          title="YouTube video player"
          frameBorder="0"
          allow="autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          sandbox="allow-forms allow-scripts allow-pointer-lock allow-same-origin allow-top-navigation"
          allowFullScreen={false}
          style={{ zIndex: 9990 }}
        ></iframe>
      )}

      {state.event_map && activeMedia === 2 && (
        <img
          src={state.event_map}
          alt="mapa"
          style={{
            width: "100%",
            height: "calc(100% - 167px)",
            top: "70px",
            position: "absolute",
          }}
        />
      )}

      {state.terminal_video && activeMedia === 3 && (
        <iframe
          width="100%"
          height="100%"
          src={`https://www.youtube.com/embed/${state.terminal_video}?controls=0&showinfo=0&autoplay=1&loop=1&mute=1&rel=0&playlist=${state.terminal_video}`}
          title="YouTube video player"
          frameBorder="0"
          allow="autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; loop"
          sandbox="allow-forms allow-scripts allow-pointer-lock allow-same-origin allow-top-navigation"
          allowFullScreen={false}
          style={{ zIndex: 9990 }}
        ></iframe>
      )}

      {state.terminal_map && activeMedia === 4 && (
        <img
          src={state.terminal_map}
          alt="mapa"
          style={{
            width: "100%",
            height: "calc(100% - 167px)",
            top: "70px",
            position: "absolute",
          }}
        />
      )}

      <div
        className="multimedia-component-modal__header d-flex justify-content-between align-items-center px-5"
        style={{ backgroundColor: "var(--events-home-bg-color" }}
      >
        <Logo
          url={assets?.events_default_logo_upload}
          style={{ maxWidth: "100px" }}
        />
        <button className="btn" style={{ color: "var(--events-home-primary-color)", fontSize: "40px" }}>
          <IoClose onClick={onClose} />
        </button>
      </div>
      <div
        className="multimedia-component-modal__footer"
        style={{ backgroundColor: "var(--events-home-bg-color" }}
      >
        {state.event_map && (
          <button
            className="px-4 main-dynamic-button d-flex align-items-center bold mx-2 py-2"
            onClick={() => _handleSelectMedia(2)}
          >
            <IoMap className="me-2 size-14" />
            {assets?.events_company_map_btn_text || "Mapa Evento"}
          </button>
        )}

        {state.event_video && (
          <button
            className="px-4 main-dynamic-button d-flex align-items-center bold mx-2 py-2"
            onClick={() => _handleSelectMedia(1)}
          >
            <IoVideocam className="me-2 size-15" />
            {assets?.events_company_video_btn_text || "Video Evento"}
          </button>
        )}

        {state.terminal_map && (
          <button
            className="px-4 main-dynamic-button d-flex align-items-center bold mx-2 py-2"
            onClick={() => _handleSelectMedia(4)}
          >
            <IoMap className="me-2 size-14" />
            {assets?.events_home_map_btn_text || "Mapa Terminal"}
          </button>
        )}

        {state.terminal_video && (
          <button
            className="px-4 main-dynamic-button d-flex align-items-center bold mx-2 py-2"
            onClick={() => _handleSelectMedia(3)}
          >
            <IoVideocam className="me-2 size-15" />
            {assets?.events_home_video_btn_text || "Video Terminal"}
          </button>
        )}
      </div>
    </div>
  );
};

export default Multimedia;
