import { useState } from "react";
import RegressiveCounter from "../../../../components/RegressiveCounter";
import Header from "../../components/Header";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { set_loader } from "../../../../redux/actions/loader";
import { useNavigate, useParams } from "react-router-dom";
import { _handleError } from "../../../../helpers/errors";
import { sleep } from "../../../../helpers/functions";
import { ReactComponent as BoxImage } from "../../../../assets/svg/box_black.svg";
import LocalTerminalService from "../../../../services/local_terminal.service";

const ConfirmationCustody = () => {
  const [intervalStateControl, setIntervalStateControl] = useState(0);
  const { order, session } = useSelector((state: any) => state);
  const dispatch: any = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const assets = session?.profile?.assets?.assets;

  const Terminal = new LocalTerminalService();

  const _handleConfirmOrder = async () => {
    try {
      dispatch(set_loader({ is_loading: true }));

      setIntervalStateControl(intervalStateControl + 1);

      sleep(1);

      await Terminal.createCustodyReservation({
        user: order.user,
        boxes: order.boxes,
        terminal: session.profile,
      });
      dispatch(set_loader({ is_loading: false }));
      navigate("/");
    } catch (e: any) {
      _handleError(e, e.message);
      dispatch(set_loader({ is_loading: false }));
    }
  };

  const _handleCancelOrder = async () => {
    try {
      navigate(`/open-box-custody/${params.phone}`);
    } catch (e: any) {
      _handleError(e, "No fue posible cancelar. ");
      dispatch(set_loader({ is_loading: false }));
    }
  };

  return (
    <div className="container-fluid h-100">
      <Header />

      <div className="d-flex justify-content-center align-items-center mt-5">
        <div
          className=" d-flex align-items-center justify-content-around flex-column p-5"
          style={{
            background: "rgba(255, 255, 255, 0.1)",
            width: "90%",
            borderRadius: "30px",
            height: "550px",
          }}
        >
          <div className="d-flex justify-content-center align-items-center text-center bold size-15">
            {assets?.custody_step_three_text_one || (
              <p>
                Si el tamaño de la caja no te sirve, selecciona la
                <br /> opción para
                <span className="color-main"> cambiar caja</span> a
                continuación.
              </p>
            )}
          </div>

          <div className="w-100">
            <div className="w-100 text-center mt-5">
              <button
                className="px-3 main-button-yellow bold"
                style={{ paddingTop: "8px", paddingBottom: "8px" }}
                onClick={_handleCancelOrder}
              >
                <BoxImage className="me-2" />
                Cambiar tamaño caja
              </button>
            </div>
            <div className="w-100 text-center mt-3">
              <button
                className="px-4 py-2 main-button bold"
                onClick={_handleConfirmOrder}
              >
                <div className="d-flex justify-content-center align-items-center">
                  <div className="me-3">Finalizar</div>
                  <RegressiveCounter
                    clearIntervalAction={intervalStateControl}
                    handleCallback={_handleConfirmOrder}
                  />
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationCustody;
