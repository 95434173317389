/* eslint-disable react-hooks/exhaustive-deps */

import { useSelector } from "react-redux";
import CardOpenBox from "../CardOpenBox";

const BoxToOpenPickup = (props: any) => {
  const { action } = props;
  const { pickup } = useSelector((state: any) => state);
  const { boxes } = pickup;
  return (
    <div className="row box py-4 justify-content-around">
      <div
        className="col-12 size-14 bold my-2 px-4 text-center"
        style={{ color: "#3f3f3f", textTransform: "uppercase" }}
      >
        Abre la(s) caja(s) y retira tu paquete
      </div>
      {boxes &&
        boxes.map((box: any) => (
          <CardOpenBox {...box} key={box.id} data={box} action={action} boxQuantity={boxes?.length}/>
        ))}
    </div>
  );
};

export default BoxToOpenPickup;
