/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Logo from "../../../../components/Logo";
import { set_session } from "../../../../redux/actions/session";
import "./component.header.scss";
import Clock from "../../../../components/Clock";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { debounce } from "lodash";

const Header = () => {
  const [showClock, setShowClock] = useState(false);

  const { session } = useSelector((state: any) => state);
  const dispatch: any = useDispatch();
  const navigate = useNavigate();

  const debouncedSave = useRef(
    debounce(() => _autoCloseSignIn(), 60000)
  ).current;

  useEffect(() => {
    if (window.location.pathname !== "/") {
      setShowClock(true);
    }

    return () => {
      debouncedSave.cancel();
    };
  }, []);

  useEffect(() => {
    if (session.sign_in_component) {
      debouncedSave();
    } else {
      debouncedSave.cancel();
    }
  }, [session]);

  const _autoCloseSignIn = () => {
    dispatch(set_session({ ...session, sign_in_component: false }));
  };

  const _handleSigIn = () => {
    dispatch(set_session({ ...session, sign_in_component: true }));
  };

  return (
    <div className="row component-header mx-5">
      <div className="col-8">
        <div style={{ width: "100px" }}>
          <button className="btn p-0 m-0" onClick={_handleSigIn}>
            <Logo />
          </button>
        </div>
      </div>
      <div className="col-4 text-end">
        {showClock ? (
          <Clock />
        ) : (
          <button
            className="main-button px-3 py-2 bold"
            onClick={() => navigate("/dashboard/signin/qr?redirect_uri=/load-articles")}
          >
            Carga
          </button>
        )}
      </div>
    </div>
  );
};

export default Header;
