/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { _pushToastMessage } from "../../../../helpers/messages";
import BoxService from "../../../../services/box.service";
import Card from "../Card";
import loaderGif from "../../../../assets/gifs/loader-transparent.gif";

const AvailableBoxes = () => {
  const [boxes, setBoxes] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const { session } = useSelector((state: any) => state);
  const Box = new BoxService();

  useEffect(() => {
    _getAvailableBoxes();
  }, []);

  const _getAvailableBoxes = async () => {
    try {
      setLoading(true);
      const response: any = await Box.available_boxes(session?.profile?.id);
      const { data } = response;

      const grouped: any = data.reduce((acc: any, current: any) => {
        if (acc[current.box_type_id]) {
          acc[current.box_type_id].push(current);
        } else {
          acc[current.box_type_id] = [current];
        }
        return acc;
      }, {});

      const boxes: any = Object.keys(grouped).map((key: any) => {
        const target: any = grouped[key][0];

        const box: any = {
          name: target?.box_type?.name,
          quantity: grouped[key]?.length,
          width: target?.box_type?.width,
          height: target?.box_type?.height,
          depht: target?.box_type?.depht,
        };
        return box;
      });

      setBoxes(boxes);

      setLoading(false);
    } catch (e: any) {
      _pushToastMessage({
        type: "warning",
        text: "No fue posible cargar las cajas disponibles",
        header: "Aviso",
      });
    }
  };

  return (
    <div className="row box py-4" style={{border: "2px solid #e3e3e3"}}>
      <div className="col-12 size-09 bold mb-2" style={{ color: "#3f3f3f" }}>
        Cajas Disponibles
      </div>

      {!loading && boxes.length === 0 && (
        <div className="col-12 size-09 d-flex justify-content-center align-items-center bold-300">
          No hay cajas disponibles
        </div>
      )}
      {loading ? (
        <div className="col-12 bold-300 size-09 d-flex justify-content-center align-items-center">
          <img
            className="me-3"
            src={loaderGif}
            alt="loader"
            style={{ width: "50px" }}
          />
          Cargando...
        </div>
      ) : (
        <div className="col-12 d-flex justify-content-center">
          {boxes && boxes.map((box: any) => <Card {...box} key={box.name} />)}
        </div>
      )}
    </div>
  );
};

export default AvailableBoxes;
