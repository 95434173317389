import { useEffect, useState } from "react";
import Header from "../../components/Header";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { handleAllColorStyles } from "../../../../helpers/dynamic_styles";
import { ReactComponent as Circle } from "../../../../assets/svg/current_color_circle.svg";
import { ReactComponent as BoxImg } from "../../../../assets/svg/current_color_box.svg";
import Multimedia from "../../components/Multimedia";
import { checkIfSomePropertyHasAValue } from "../../../../helpers/functions";

const ActiveReservations = () => {
  const { order, session } = useSelector((state: any) => state);
  const navigate = useNavigate();

  const assets = session?.profile?.assets?.assets;

  const [multimediaControl, setMultimediaControl] = useState({
    open: false,
    mediaData: {},
  });

  useEffect(() => {
    handleAllColorStyles(assets);
  }, [assets]);

  const _handleMediaComponent = (terminal: any) => {
    const assets = terminal?.assets?.assets;
    const mediaData = {
      events_company_map_video_url: assets?.events_company_map_video_url,
      events_company_map_img_upload: assets?.events_company_map_img_upload,
      events_default_company_map_video_url:
        assets?.events_default_company_map_video_url,
      events_default_company_map_img_upload:
        assets?.events_default_company_map_img_upload,
    };

    if (!checkIfSomePropertyHasAValue(mediaData)) return;
    
    setMultimediaControl({ open: true, mediaData });
  };

  const _handleNewReservation = () => {
    /**
     * check if select box is required
     * if not
     */
    if (!assets?.events_delivery_boxes_required) {
      /**
       * check if pinpad is required
       */
      if (assets?.events_delivery_pinpad_required) {
        navigate("/validate-pin?redirect_to=open-box-and-finish");
      } else {
        navigate("/open-box-and-finish");
      }
      return;
    }

    /**
     * if select box is required, check if pinpad is required
     */
    if (assets?.events_delivery_pinpad_required) {
      navigate("/validate-pin?redirect_to=open-box-delivery");
    } else {
      navigate("/open-box-delivery");
    }
  };

  const _checkIfExistMedia = (terminal: any) => {
    const assets = terminal?.assets?.assets;
    const mediaData = {
      events_company_map_video_url: assets?.events_company_map_video_url,
      events_company_map_img_upload: assets?.events_company_map_img_upload,
      events_default_company_map_video_url:
        assets?.events_default_company_map_video_url,
      events_default_company_map_img_upload:
        assets?.events_default_company_map_img_upload,
    };

    return checkIfSomePropertyHasAValue(mediaData);
  };

  return (
    <div className="container-fluid h-100">
      <Header />
      <Multimedia
        open={multimediaControl?.open}
        mediaData={multimediaControl?.mediaData}
        onClose={() =>
          setMultimediaControl({ ...multimediaControl, open: false })
        }
        priority={1}
      />
      <div className="d-flex justify-content-center align-items-center mt-5">
        <div
          className=" d-flex align-items-center justify-content-around flex-column p-5"
          style={{
            background: "var(--events-default-backdrop-color)",
            width: "90%",
            borderRadius: "30px",
            height: "550px",
          }}
        >
          {order?.active_reservations_in_others_terminals
            ?.can_create_reservation ? (
            <div className="d-flex justify-content-center align-items-center text-center bold size-14">
              {order?.active_reservations_in_others_terminals?.setup
                ?.events_config?.max_reservations_message_two ? (
                <p>
                  {
                    order?.active_reservations_in_others_terminals?.setup
                      ?.events_config?.max_reservations_message_two
                  }
                </p>
              ) : (
                <p>
                  Sus pertenencias se encuentran en otro locker. A continuación
                  puede ver cual el locker y como llegar hasta el. Haga click en
                  guardar para guardar algo en este locker.
                </p>
              )}
            </div>
          ) : (
            <div className="text-center bold size-15">
              {order?.active_reservations_in_others_terminals?.setup
                ?.events_config?.max_reservations_message_one ? (
                <>
                  <p>
                    {
                      order?.active_reservations_in_others_terminals?.setup
                        ?.events_config?.max_reservations_message_one
                    }
                  </p>
                </>
              ) : (
                <>
                  <p>
                    Sus pertenencias se encuentran en otro locker. A
                    continuación puede ver cual el locker y como llegar hasta
                    el.sss
                  </p>
                </>
              )}
            </div>
          )}

          <div
            className="w-100 d-flex flex-wrap justify-content-around scrollbar"
            style={{ overflowX: "auto", maxHeight: "350px" }}
          >
            {order?.active_reservations_in_others_terminals?.reservations?.map(
              (reservation: any, index: number) => (
                <div
                  className="my-2 d-flex align-items-center justify-content-start px-3 text-black"
                  key={index}
                  style={{
                    backgroundColor: "var(--events-default-backdrop-box-color)",
                    width: "350px",
                    height: "100px",
                    borderRadius: "10px",
                  }}
                  onClick={() => _handleMediaComponent(reservation.terminal)}
                >
                  <div
                    className="d-flex justify-content-center align-items-center position-relative"
                    style={{ width: "70px" }}
                  >
                    <Circle
                      className="events-dynamic-color-circle"
                      style={{
                        position: "absolute",
                        width: "70px",
                        height: "70px",
                      }}
                    />
                    <BoxImg
                      className="events-dynamic-color-box"
                      style={{
                        position: "absolute",
                        width: "40px",
                        height: "40px",
                      }}
                    />
                  </div>
                  <div
                    className="ms-4 events-dynamic-box-text-color"
                    style={{ width: "230px" }}
                  >
                    <div className="bold">{reservation?.terminal?.name}</div>
                    <div className="d-flex flex-column size-08">
                      <div>{reservation?.terminal?.address}</div>
                    </div>
                    {_checkIfExistMedia(reservation.terminal) ? (
                      <div
                        className="size-08 text-end bold pt-2"
                        style={{ color: "var(--events-home-btn-bg-color)" }}
                      >
                        Ver Mapa
                      </div>
                    ) : null}
                  </div>
                </div>
              )
            )}
          </div>

          <div className="w-100">
            <div className="w-100 mt-5 d-flex justify-content-center">
              <button
                className="px-4 py-2 main-borderless-dynamic-button bold"
                style={{ paddingTop: "8px", paddingBottom: "8px" }}
                onClick={() => navigate("/")}
              >
                Volver
              </button>

              {order?.active_reservations_in_others_terminals
                ?.can_create_reservation ? (
                <button
                  className="px-4 main-inverted-dynamic-button bold ms-4"
                  onClick={_handleNewReservation}
                  disabled={
                    !order?.active_reservations_in_others_terminals
                      ?.can_create_reservation
                  }
                >
                  <div className="d-flex justify-content-center align-items-center">
                    <div>Guardar</div>
                  </div>
                </button>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActiveReservations;
