import api from "./api_locker";
import store from "../redux/store";

class LocalDeliveryUserService {
  constructor() {
    const { session } = store.getState();
    api.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${session.security_token}`;
  }

  list_users = async (payload: any) => {
    try {
      const response = await api.get(
        `/api/v1/terminal/delivery-users/${payload}`,
        payload
      );
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };
}

export default LocalDeliveryUserService;
