import { useEffect } from "react";
import Header from "../../components/Header";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { handleAllColorStyles } from "../../../../helpers/dynamic_styles";

const MaxReservations = () => {
  const { order, session } = useSelector((state: any) => state);
  const navigate = useNavigate();

  const assets = session?.profile?.assets?.assets;

  useEffect(() => {
    handleAllColorStyles(assets);
  }, [assets]);

  return (
    <div className="container-fluid h-100">
      <Header />

      <div className="d-flex justify-content-center align-items-center mt-5">
        <div
          className=" d-flex align-items-center justify-content-around flex-column p-5"
          style={{
            background: "var(--events-default-backdrop-color)",
            width: "90%",
            borderRadius: "30px",
            height: "550px",
          }}
        >
          <div className="d-flex justify-content-center align-items-center text-center bold size-15">
            {order?.active_reservations_in_others_terminals?.setup?.events_config?.max_reservations_message ? (
              <p className="size-12">
                {order?.active_reservations_in_others_terminals?.setup?.events_config?.max_reservations_message}
              </p>
            ) : (
              <p className="size-12">
                Se ha alcanzado el límite de reservas simultáneas para su usuario. Finaliza tu reserva actual para poder crear otra.
              </p>
            )}
          </div>

          <div className="w-100">
            <div className="w-100 mt-5 d-flex justify-content-center">
              <button
                className="px-4 py-2 main-borderless-dynamic-button bold"
                style={{ paddingTop: "8px", paddingBottom: "8px" }}
                onClick={() => navigate("/")}
              >
                Volver
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MaxReservations;
